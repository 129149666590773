@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.schedule-calendar {
  
  &__wrap {
    width: 100%;
    padding-top: 0;
    margin-left: 0;
    margin-right: 0;
    background-color: white;
    overflow-x: auto;
    display:inline-table;

    .inv-btn {
      margin-left: 20px;
      margin-top:16px;
      margin-bottom:14px;
      font-size: 16px;
      color: #0a84ff;
      text-decoration: underline;
    }
  }

  &__title {
    height: 50px;
    padding-top: 12px;
    padding-left: 20px;
    margin-bottom: 0;
    @font-face {
      font-family: 'AvertaRegular';
      src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 15px;
    font-weight: 500;
    color: rgba(117,117,117,255);
    display:inline-block;
  }

  &_header {
    border-bottom: 1px solid rgba(208,208,212,255);
    display: flex;
    justify-content: space-between;
  }

  &_tz {
    margin-top:15px;
    margin-right: 20px;
    color: #757575;
  }
}

.total-hours-table {
  margin-top:2px;
  width: 100%;
  border-top: 1px solid #d0d0d4;
}

.total-hours{
  padding-right:10px;
}

.tooltip{
  position: absolute;
  display: flex;
  flex-direction: column;
  max-width: 200px;
}

img.tooltip-caret {
  margin-top: 0px;
  display: flex;
  vertical-align: top;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  left:0px;
  right:0px;
  pointer-events: none; /* Prevents tooltip from blocking mouse events */
  cursor: pointer;
}

.tooltip-main {
  display: flex;
  background-color: #2c2c2e;
  color: white;
  padding: 8px;
  margin-top: -10px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 1000;
  pointer-events: none; /* Prevents tooltip from blocking mouse events */
  transition: opacity 0.3s;
  cursor: pointer;
  text-align: center;
}

.tooltip-hidden{
  display: flex;
  padding: 8px;
  border-radius: 5px;
  font-size: 12px;
  pointer-events: none; /* Prevents tooltip from blocking mouse events */
  visibility: hidden;
  cursor: pointer;
  text-align: center;
}
