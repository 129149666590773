.confirm {
  &__title {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 24px;
    //border: 1px solid red;
  }
  &__button-wrap {
    display: flex;
    flex-direction: row;
    margin-top: 14px;
    margin-left: 150px;
    margin-bottom: 20px;
    //border: 1px solid red;
  }
  &__button-cancel {
    height: 34px;
    width: 90px;
    margin-left: 10px;
    border-radius: 7px;
    border: 1px solid rgba(153,156,161,255);
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    //color: white;
    background-color: rgba(242,242,247,255);

    &:hover {
      cursor: pointer;
      //color: white;
      //background-color: rgba(11,132,255,255);
      //border: 1px solid rgba(11,132,255,255);
    }
  }

  &__button-leave {
    height: 34px;
    min-width: 90px;
    margin-left: 10px;
    border-radius: 7px;
    border: 1px solid rgba(153,156,161,255);
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: white;
    background-color: rgba(11,132,255,255);
    border: 1px solid rgba(11,132,255,255);
    padding-left: 10px;
    padding-right: 10px;
    
    &:hover {
      cursor: pointer;
      color: white;
      background-color: rgba(11,132,255,255);
      border: 1px solid rgba(11,132,255,255);
    }
  }

  &__text {
    margin-top: 3px;
    height: 20px;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    overflow: visible;
    white-space: nowrap;
    //border: 1px solid blue;
  }
}
