@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

$padLeft: 20px;

.table {
  z-index: 1;
  width: 100%;
  margin-bottom: 50px;
  border-collapse: collapse;
  //border: 1px solid red;

  &.loading {
    z-index: -1;
  }

  &__wrap {
    position: relative;
    width: 100%;
    display: block;
    height: 430px;
    overflow-y: scroll;
    //border: 1px solid blue;
  }

  &__header-title {
    padding-left: $padLeft;

    @font-face {
      font-family: 'AvertaLight';
      src: url('/fonts/averta/Averta-Light.ttf');
    }

    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 600;
    color: rgba(9, 9, 17, 255);
    //--------Sticky column header
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white; //very important! without this, the header is transparent
    //---------Sticky column header
  }

  &__headers {
    text-align: left;
    height: 50px; //makes space between header and body
    border-bottom: 1px solid rgba(208, 208, 212, 255);
    //position: sticky;
    //top: 0px;
    //z-index: 15;
    //border: 1px solid red;
  }

  &__body {
    //display: block;
    //height: 280px;
    //overflow-y: auto;
    //border: 1px solid blue;
    resize: none;

    &:hover {
      //color: transparent;
      //text-shadow: 0 0 3px #aaa;
    }
  }
}

.user-row {

  &__row {
    margin-left: 20px;
    border-bottom: 1px solid rgba(208, 208, 212, 255);
    resize: none;
    //border: 1px solid red;
  }

  &__row-blurred {
    margin-left: 20px;
    border-bottom: 1px solid rgba(208, 208, 212, 255);
    opacity: 0.5;
    pointer-events: none; //disabled
  }

  &__file-name-cell {
    padding-left: $padLeft;
    //border: 1px solid red;
  }

  &__owner-cell {
    padding-top: 17px;
    padding-bottom: 17px;
    padding-left: $padLeft;
    //border: 1px solid red;
  }

  &__status-cell {
    padding-left: $padLeft;
    //border: 1px solid red;
  }

  &__departments-wrap {
    padding-left: $padLeft;
    height: 30px;
    resize: none;
  }

  &__departments {
    padding-left: 10px;
    padding-right: 10px;

    @font-face {
      font-family: 'AvertaRegular';
      src: url('/fonts/averta/Averta-Regular.ttf');
    }

    font-family: 'AvertaRegular';
    font-size: 15px;
    font-weight: 600;
    color: black;
    background-color: rgba(242, 242, 247, 255);
    border-radius: 4px;
    //border: 1px solid red;
  }

  &__departments-none {
    padding-left: 10px;
    padding-right: 10px;

    @font-face {
      font-family: 'AvertaLight';
      src: url('/fonts/averta/Averta-Light.ttf');
    }

    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 600;
    color: black;
    //border: 1px solid red;
  }


  &__groups {
    padding-left: $padLeft;

    @font-face {
      font-family: 'AvertaRegular';
      src: url('/fonts/averta/Averta-Regular.ttf');
    }

    font-family: 'AvertaRegular';
    font-size: 15px;
    font-weight: 800;
    color: rgba(0, 203, 74, 255);
    //border: 1px solid red;
  }

  &__skills {
    padding-left: 10px;
    padding-right: 10px;

    @font-face {
      font-family: 'AvertaRegular';
      src: url('/fonts/averta/Averta-Regular.ttf');
    }

    font-family: 'AvertaRegular';
    font-size: 15px;
    font-weight: 600;
    color: black;
    background-color: rgba(242, 242, 247, 255);
    border-radius: 4px;
    //border: 1px solid red;
  }

  &__skills-none {
    padding-left: 10px;
    padding-right: 10px;

    @font-face {
      font-family: 'AvertaLight';
      src: url('/fonts/averta/Averta-Light.ttf');
    }

    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 600;
    color: black;
    //border: 1px solid red;
  }

  &__checkbox {
    padding-left: $padLeft;
    //border: 1px solid red;
    vertical-align: middle;
  }

  &__td-centered {
    text-align: center;
    vertical-align: middle;
  }

  &__user-role {
    padding-left: $padLeft;

    @font-face {
      font-family: 'AvertaLight';
      src: url('/fonts/averta/Averta-Light.ttf');
    }

    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 600;
    color: rgba(9, 9, 17, 255);
    //border: 1px solid red;
  }


  &__user-datetime {
    @font-face {
      font-family: 'AvertaRegular';
      src: url('/fonts/averta/Averta-Regular.ttf');
    }

    font-family: 'AvertaRegular';
    font-size: 12px;
    font-weight: 500;
    color: rgba(44, 44, 46, 255);
    vertical-align: middle;
  }

  &__button-wrap {
    margin-left: 0px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 90px;
    resize: none;
    //border: 1px solid red;
  }

  &__arrow-button-collapse {
    width: 20px;
    height: 20px;
    background-image: url("../../assets/collapse-icon.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    border: none;

    &:hover {
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }

    &:focus {
      outline: none;
    }
  }

  &__arrow-button-expand {
    width: 20px;
    height: 20px;
    background-image: url("../../assets/expand-icon.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    border: none;

    &:hover {
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }

    &:focus {
      outline: none;
    }
  }

  &__contact-button-location {
    width: 20px;
    height: 20px;
    //border-radius: 6px;
    //border: 1px solid rgba(115,124,132,255);
    background-image: url("../../assets/location-icon.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }

    &:focus {
      outline: none;
    }
  }

  &__contact-button-location-disabled {
    width: 20px;
    height: 20px;
    //border-radius: 6px;
    //border: 1px solid rgba(115,124,132,255);
    background-image: url("../../assets/location-disabled.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      cursor: default;
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }

    &:focus {
      outline: none;
    }
  }

  &__contact-button-skills {
    width: 25px;
    height: 20px;
    //border-radius: 6px;
    //border: 1px solid rgba(115,124,132,255);
    background-image: url("../../assets/skills-icon.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }

    &:focus {
      outline: none;
    }
  }

  &__contact-button-skills-disabled {
    width: 25px;
    height: 20px;
    //border-radius: 6px;
    //border: 1px solid rgba(115,124,132,255);
    background-image: url("../../assets/skills-disabled.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      cursor: default;
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }

    &:focus {
      outline: none;
    }
  }

  &__contact-button-group-department {
    width: 25px;
    height: 20px;
    //border-radius: 6px;
    //border: 1px solid rgba(115,124,132,255);
    background-image: url("../../assets/group-department-icon.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }

    &:focus {
      outline: none;
    }
  }

  &__contact-button-group-department-disabled {
    width: 25px;
    height: 20px;
    //border-radius: 6px;
    //border: 1px solid rgba(115,124,132,255);
    background-image: url("../../assets/group-department-disabled.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      cursor: default;
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }

    &:focus {
      outline: none;
    }
  }

  &__contact-button-chat {
    width: 20px;
    height: 20px;
    //border-radius: 6px;
    //border: 1px solid rgba(115,124,132,255);
    background-image: url("../../assets/chat-icon.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      color: rgba(0, 135, 255, 255);
      background-color: white;
      border: 1px solid rgba(0, 135, 255, 255);
    }
  }

  &__button-more {
    width: 20px;
    height: 20px;
    //border-radius: 6px;
    //border: 1px solid rgba(115,124,132,255);
    background-image: url("../../assets/ellipses-icon.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }

    &:focus {
      outline: none;
    }
  }

  &__group-member-count {
    margin-left: 7px;
    padding-left: 5px;
    padding-right: 5px;

    @font-face {
      font-family: 'AvertaRegular';
      src: url('/fonts/averta/Averta-Regular.ttf');
    }

    font-family: 'AvertaRegular';
    font-size: 15px;
    font-weight: 600;
    color: black;
    background-color: rgba(242, 242, 247, 255);
    //background-color: green;
    border-radius: 4px;
    //border: 1px solid red;
  }

  &__skill-member-count {
    margin-left: 7px;
    padding-left: 5px;
    padding-right: 5px;

    @font-face {
      font-family: 'AvertaRegular';
      src: url('/fonts/averta/Averta-Regular.ttf');
    }

    font-family: 'AvertaRegular';
    font-size: 15px;
    font-weight: 600;
    color: black;
    background-color: rgba(242, 242, 247, 255);
    //background-color: green;
    border-radius: 4px;
    //border: 1px solid red;
  }
}

.mouse-tip {
  &__top-wrap {
    margin-top: 1px;
    margin-left: 15px;
    margin-right: 15px;

    @font-face {
      font-family: 'AvertaLight';
      src: url('/fonts/averta/Averta-Light.ttf');
    }

    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 600;
    //border: 1px solid red;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-right: 15px;
    //border: 1px solid red;
  }

  &__title {
    @font-face {
      font-family: 'AvertaBold';
      src: url('/fonts/averta/Averta-Bold.ttf');
    }

    font-family: 'AvertaBold';
    font-size: 14px;
    font-weight: 400;
    //border: 1px solid red;
  }

  &__content {
    @font-face {
      font-family: 'AvertaLight';
      src: url('/fonts/averta/Averta-Light.ttf');
    }

    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 400;
    //border: 1px solid blue;
  }

  &__text {
    @font-face {
      font-family: 'AvertaLight';
      src: url('/fonts/averta/Averta-Light.ttf');
    }

    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 400;
    color: black;
    //border: 1px solid blue;
  }
}

.no-results {
  &__wrap {
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 50%;
    //border: 1px solid red;

  }

  &__icon {
    width: 15px;
    height: 15px;
    margin-top: 5px;
    margin-right: 8px;
    //border: 1px solid red;
  }

  &__text {
    margin-top: 4px;

    @font-face {
      font-family: 'AvertaLight';
      src: url('/fonts/averta/Averta-Light.ttf');
    }

    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 400;
  }
}