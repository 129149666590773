
.configuration-container {
    width: 611px;
    display:flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius:8px;
    padding-left: 48px;  
    padding-right: 48px;  
    padding-top: 36px;
    margin-top: 56px;
    min-height:640px;
}

.configuration-title {
    color: #2C2C2E;
    font-size: 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid #d0d0d4;
}

.week-inline {
    display: flex;
    justify-content: space-between;
}

.week-start-text {
    display: inline-block;
    vertical-align: middle;
    line-height: 84px;
    color: #2C2C2E;
    font-size: 18px;
}

button.week-dropdown {
    text-transform: capitalize;
    display: inline-block;
    border: 1px solid #d0d0d4; 
    height: 38px;
    min-width: 148px; 
    margin-top: auto;
    margin-bottom: auto;
    
    font-size: 18px;
    font-weight: 400;
    border-radius: 4px;
    background-image: url("/icons/down-arrow.svg");
    background-repeat: no-repeat;
    background-position: 90% 50%;
    padding-right: 56px;
    padding-left: 24px;
}

.weekday-list {
    border: 1px solid #d0d0d4; 
    min-width: 148px;
    display: flex;
    margin-right:0px;
    margin-left: auto;
    border-radius: 4px;
    margin-top: -12px;
    margin-bottom:10px;

    ul {
        padding-left: 0px;
        margin-bottom:0px;

   
    }

    ul li {
        list-style: none;
        padding-left:20px;
        padding-top:4px;
        padding-bottom:4px;
        min-width:144px;
        width:100%;
        cursor: pointer;
        justify-content: space-between;
        display:flex;
        padding-right:8px;
        text-transform: capitalize;

        &:hover {
            background-color: #f2f2f7 ;
        }
    }

    ul li.weekday-active span {
            font-family:'AvertaSemibold';
    }

}