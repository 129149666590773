

.averta-bold {
    @font-face{
     font-family: 'AvertaBold';
     src: url('/fonts/averta/Averta-Bold.ttf');
    }
     font-family: 'AvertaBold';
}

.averta-regular {
    @font-face {
     font-family: 'AvertaRegular';
     src: url('/fonts/averta/Averta-Regular.ttf');
    }
     font-family: 'AvertaRegular';
}

.averta-semibold {
    @font-face {
     font-family: 'AvertaSemiBold';
     src: url('/fonts/averta/Averta-Semibold.ttf');
    }
     font-family: 'AvertaSemiBold';
}

.averta-light {
    @font-face {
     font-family: 'AvertaLight';
     src: url('/fonts/averta/Averta-Light.ttf');
    }
     font-family: 'AvertaLight';
}

.ff-bold {
    @font-face{
     font-family: 'AvertaBold';
     src: url('/fonts/averta/Averta-Bold.ttf');
    }
     font-family: 'AvertaBold';
}

.ff-regular {
    @font-face {
     font-family: 'AvertaRegular';
     src: url('/fonts/averta/Averta-Regular.ttf');
    }
     font-family: 'AvertaRegular';
}

.ff-bold{
    @font-face {
     font-family: 'AvertaBold';
     src: url('/fonts/averta/Averta-Bold.ttf');
    }
     font-family: 'AvertaBold';
}


.ff-semibold {
    @font-face {
     font-family: 'AvertaSemiBold';
     src: url('/fonts/averta/Averta-Semibold.ttf');
    }
     font-family: 'AvertaSemiBold';
}

.ff-light {
    @font-face {
     font-family: 'AvertaLight';
     src: url('/fonts/averta/Averta-Light.ttf');
    }
     font-family: 'AvertaLight';
}

.fs11px { font-size: 11px; }
.fs12px { font-size: 12px; }
.fs13px { font-size: 13px; }
.fs14px { font-size: 14px; }
.fs15px { font-size: 15px; }
.fs16px { font-size: 16px; }
.fs17px { font-size: 16px; }
.fs18px { font-size: 17px; }
.fs19px { font-size: 19px; }
.fs20px { font-size: 20px; }
.fs21px { font-size: 21px; }
.fs22px { font-size: 22px; }

.fs8 { font-size: 8px; }
.fs9 { font-size: 9px; }
.fs10 { font-size: 10px; }
.fs11 { font-size: 11px; }
.fs12 { font-size: 12px; }
.fs14 { font-size: 14px; }
.fs15 { font-size: 15px; }
.fs16 { font-size: 16px; }
.fs17 { font-size: 16px; }
.fs18 { font-size: 17px; }
.fs19 { font-size: 19px; }
.fs20 { font-size: 20px; }
.fs21 { font-size: 21px; }
.fs22 { font-size: 22px; }

.fw200 {font-weight: 200;}