.view-plan-subscribed {
  background-color: #f2f2f7;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.view-plan-subscribed .div {
  background-color: #f2f2f7;
  height: 1080px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.view-plan-subscribed .overlap {
  background-color: #ffffff;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 611px;
  min-height:640px;
  margin-top:56px;
}

.view-plan-subscribed .row {
  color: #2c2c2e;
  font-family: "AvertaRegular", Helvetica;
  font-size: 20px;
  font-weight: 300;
  left: 71px;
  letter-spacing: -0.67px;
  line-height: 22.5px;
  position: absolute;
  top: 40px;
  white-space: nowrap;
  border-bottom: 1px solid #C7C7CC;
  padding-bottom: 20px;
  width: 514px;
}

.view-plan-subscribed .group {
  left: 48px;
  position: absolute;
  top: 104px;
  width: 300px;
}

.view-plan-subscribed .text-wrapper {
  color: #6d7278;
  font-family: "AvertaRegular", Helvetica;
  font-size: 16px;
  font-weight: 300;
  left: 0;
  letter-spacing: -0.54px;
  line-height: 22.5px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.view-plan-subscribed .text-wrapper-2 {
  color: #2c2c2e;
  font-family: "AvertaSemibold", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.26px;
  line-height: normal;
  position: absolute;
  top: 36px;
}

.view-plan-subscribed .your-plan-renews-on {
  color: #2c2c2e;
  font-family: "AvertaRegular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.26px;
  line-height: normal;
  position: absolute;
  top: 68px;
}

.view-plan-subscribed .group-2 {
  height: 72px;
  left: 415px;
  position: absolute;
  top: 108px;
  width: 143px;
}

.view-plan-subscribed .div-wrapper {
  background-color: #0a84ff;
  border-radius: 8px;
  height: 32px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 143px;
}

.view-plan-subscribed .text-wrapper-3 {
  color: #ffffff;
  font-family: "AvertaSemibold", Helvetica;
  font-size: 13px;
  font-weight: 400;
  width: 100%;
  letter-spacing: -0.15px;
  line-height: normal;
  position: absolute;
  text-align: center;
  padding-top: 9px;
  white-space: nowrap;
  height:32px;
  text-decoration:none;
}

.view-plan-subscribed .group-copy {
  background-color: #f2f2f7;
  border: 1px solid;
  border-color: #6d7278;
  border-radius: 8px;
  height: 32px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 40px;
  width: 143px;
}

.view-plan-subscribed .text-wrapper-4 {
  color: #2c2c2e;
  font-family: "AvertaSemibold", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 36px;
  letter-spacing: -0.15px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 7px;
  white-space: nowrap;
}

.view-plan-subscribed .path {
  height: 1px;
  left: 63px;
  position: absolute;
  top: 77px;
  width: 485px;
}

.view-plan-subscribed .navigation {
  height: 1080px;
  left: 0;
  position: absolute;
  top: 0;
  width: 62px;
}

.view-plan-subscribed .overlap-group {
  background-color: #2c2c2e;
  height: 1082px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 64px;
}

.view-plan-subscribed .nav-icon {
  height: 62px;
  left: 1px;
  position: absolute;
  top: 187px;
  width: 62px;
}

.view-plan-subscribed .overlap-group-2 {
  background-color: #2c2c2e;
  height: 64px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 64px;
}

.view-plan-subscribed .shape {
  height: 19px;
  left: 25px;
  position: absolute;
  top: 23px;
  width: 15px;
}

.view-plan-subscribed .group-3 {
  height: 186px;
  left: 1px;
  position: absolute;
  top: 871px;
  width: 62px;
}

.view-plan-subscribed .group-4 {
  height: 62px;
  left: 0;
  position: absolute;
  top: 124px;
  width: 62px;
}

.view-plan-subscribed .oval {
  height: 32px;
  left: 15px;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 32px;
}

.view-plan-subscribed .overlap-group-wrapper {
  height: 62px;
  left: 0;
  position: absolute;
  top: 62px;
  width: 62px;
}

.view-plan-subscribed .newcom-logo-circle {
  height: 32px;
  left: 16px;
  position: absolute;
  top: 16px;
  width: 32px;
}

.view-plan-subscribed .overlap-wrapper {
  height: 62px;
  left: 0;
  position: absolute;
  top: 0;
  width: 62px;
}

.view-plan-subscribed .noun-cog {
  height: 20px;
  left: 23px;
  position: relative;
  top: 22px;
  width: 19px;
}

.view-plan-subscribed .combined-shape {
  height: 20px;
  left: 459px;
  position: absolute;
  top: -102px;
  width: 19px;
}

.view-plan-subscribed .nav-icon-2 {
  height: 62px;
  left: 1px;
  position: absolute;
  top: 1px;
  width: 62px;
}

.view-plan-subscribed .master-shyft-logo {
  height: 30px;
  left: 17px;
  position: absolute;
  top: 17px;
  width: 30px;
}

.view-plan-subscribed .nav-icon-3 {
  height: 62px;
  left: 1px;
  position: absolute;
  top: 311px;
  width: 62px;
}

.view-plan-subscribed .users-light-wrapper {
  height: 20px;
  left: 22px;
  position: relative;
  top: 22px;
  width: 20px;
}

.view-plan-subscribed .users-light {
  height: 20px;
  position: relative;
}

.view-plan-subscribed .img {
  height: 20px;
  left: 459px;
  position: absolute;
  top: -102px;
  width: 20px;
}

.view-plan-subscribed .combined-shape-2 {
  height: 12px;
  left: 1px;
  position: absolute;
  top: 4px;
  width: 16px;
}

.view-plan-subscribed .nav-icon-copy {
  height: 62px;
  left: 1px;
  position: absolute;
  top: 373px;
  width: 62px;
}

.view-plan-subscribed .nav-icon-copy-2 {
  height: 62px;
  left: 1px;
  position: absolute;
  top: 249px;
  width: 62px;
}

.view-plan-subscribed .navigation-inactive-wrapper {
  height: 16px;
  left: 24px;
  position: relative;
  top: 25px;
  width: 17px;
}

.view-plan-subscribed .navigation-inactive {
  height: 16px;
}

.view-plan-subscribed .navigation-inactive-2 {
  height: 16px;
  width: 17px;
}

.view-plan-subscribed .overlap-group-3 {
  height: 17px;
  position: relative;
  width: 18px;
}

.view-plan-subscribed .combined-shape-copy {
  height: 13px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 15px;
}

.view-plan-subscribed .combined-shape-copy-2 {
  height: 13px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 15px;
}

.view-plan-subscribed .nav-icon-4 {
  height: 62px;
  left: 1px;
  position: absolute;
  top: 125px;
  width: 62px;
}

.view-plan-subscribed .overlap-2 {
  background-color: #3f3f41;
  height: 64px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 64px;
}

.view-plan-subscribed .rectangle {
  background-color: #ffffff;
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 6px;
}

.view-plan-subscribed .menu-item {
  height: 18px;
  left: 25px;
  position: absolute;
  top: 24px;
  width: 15px;
}

.view-plan-subscribed .navigation-active-no {
  height: 18px;
}

.view-plan-subscribed .navigation-active-no-2 {
  height: 18px;
  width: 15px;
}

.view-plan-subscribed .overlap-group-4 {
  height: 18px;
  position: relative;
  width: 16px;
}

.view-plan-subscribed .combined-shape-3 {
  height: 17px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 16px;
}

.view-plan-subscribed .combined-shape-4 {
  height: 4px;
  left: 11px;
  position: absolute;
  top: 0;
  width: 2px;
}

.view-plan-subscribed .combined-shape-5 {
  height: 7px;
  left: 2px;
  position: absolute;
  top: 8px;
  width: 10px;
}

.view-plan-subscribed .nav-icon-5 {
  height: 62px;
  left: 1px;
  position: absolute;
  top: 63px;
  width: 62px;
}

.view-plan-subscribed .time {
  color: #2c2c2e;
  font-family: "AvertaRegular", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 84px;
  letter-spacing: -0.5px;
  line-height: 22.5px;
  position: absolute;
  top: 18px;
  white-space: nowrap;
}

.view-plan-subscribed .time-copy {
  color: #2c2c2e;
  font-family: "AvertaRegular", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 1127px;
  letter-spacing: -0.5px;
  line-height: 22.5px;
  position: absolute;
  text-align: right;
  top: 18px;
  white-space: nowrap;
}

.view-plan-subscribed .path-2 {
  height: 1px;
  left: 62px;
  position: absolute;
  top: 56px;
  width: 1378px;
}

.view-plan-subscribed .text-wrapper-5 {
  color: #0a84ff;
  font-family: "AvertaSemibold", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 328px;
  letter-spacing: -0.89px;
  line-height: 22.5px;
  position: absolute;
  text-align: right;
  top: 199px;
  white-space: nowrap;
}

.view-plan-subscribed .path-3 {
  height: 2px;
  left: 324px;
  position: absolute;
  top: 223px;
  width: 64px;
}
