
.lightgray {
    color: #6d7278;
}

.primaryblack {
    color: #2c2c2e;
}

.c-2c2c2e {
    color: #2c2c2e;
}

.c-ffffff{
    color: #ffffff;
}

.bc-2c2c2e {
    background-color: #2c2c2e;
}

.c969696 {
    color: #969696;
}

.rederror {
    color: #FF4650;
}