@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.management {
  
  &__wrap {
    //@extend %inner-wrap;
    width: 100%;
    height: 100%;
    padding-top: 0;
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
    background-color: white;
    position: relative;
    //overflow: scroll;
    //border: 1px solid red;
  }

  &__title {
    height: 50px;
    padding-top: 10px;
    padding-left: 20px;
    margin-bottom: 0;
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 15px;
    font-weight: 500;
    color: rgba(117,117,117,255);
    border-bottom: 1px solid rgba(208,208,212,255);
    //border: 1px solid blue;
  }

  @media (max-width: 680px) {
    padding-top: 0;
    margin-top: 50px;
  }
  &__main-body {
    height: 75%;
    //border: 1px solid blue;
    overflow: scroll;
  }

  &__summary-wrap {
    height: 70px;
    padding-top: 10px;
    padding-left: 20px;
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 15px;
    font-weight: 500;
    color: rgba(117,117,117,255);
    border-bottom: 1px solid rgba(208,208,212,255);
  }
}

.LoadingLayer {
  display: flex;
  height: 150px;
  width: 100%;
  top: 45%;
  left: 50%;
  margin: auto;
  //border: 1px solid red;

  > img {
    display: block;
    margin: auto;
    margin-top: 45px;
  }
}

.status {
  &__wrap {
    padding-left: 50px;
    padding-right: 50px;
    //border: 1px solid red;
  }

  &__text {
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 600;
    color: white;
  }
}

.manager--loading-layer {
  position: absolute;
  top: 300px;
  right: 100px;
  left: 100px;
  bottom: 0;
  opacity: 0;
  display: none;

  &.show {
    display: block;
    z-index: 1;
    opacity: 0.6;

    & img {
      position: relative;
      height: 50px;
      width: 50px;
      top: 50px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}