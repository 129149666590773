.search-filter-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 12px;
    margin-top: 18px;

    .search-box {
        height: 38px;
        border-radius: 8px;
        border: 1px solid #737c84;
        margin-top: 8px;
        padding-left: 40px;
        width: 272px;
        background-image: url(/static/media/search-icon.05d52137.svg);
        background-repeat: no-repeat;
        background-position: 14px 10px;
    }

    .filter-dropdown {
        z-index: 3;
        position: absolute;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); 
        background-color: #ffffff;
        border-radius: 4px;
        margin-left: 16px;
        margin-top: 46px;
        font-size: 14px;

        ul.filter-list {
            list-style-type: none;
            padding-left: 0px;
            padding-right: 0px;
            padding-bottom: 0px;
            margin-bottom: 4px;
            margin-top: 4px;

            li {
                cursor: pointer;
                padding-left: 8px;
                padding-right: 8px;
                &:hover {
                    background-color: #dddddd;
                }
            }
        }
    }

    .filter-logic {
        display: flex;
        flex-direction: row;
        height: 38px;
        border-radius: 19px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 8px;
        padding-bottom: 8px;
        border: 1px solid #0b84ff;
        color: #0b84ff;
        margin-left: 18px;
        margin-top: 8px;

        .filter-subject {
            font-size: 14px;
        }

        .filter-object {
            font-size: 14px; 
            margin-left: 8px;
        }

        button.close-btn {
            margin-left: 10px;
        }
    }
}

.zero-col-container {
    display: flex;
    flex-direction: column;
}

.search-label {
    font-size: 12px;
    color: #bbbbbb;
    margin-left: 42px;
    margin-top: 4px;
}

.first-col-container {
    display: flex;
    flex-direction: column;
}

.second-col-container {
    display: flex;
    flex-direction: column;

    .add-filter-button {
        border: 1px solid #737c84;
        padding: 10px;
        border-radius: 8px;
        font-size: 12px;
        margin-left: 16px;
        margin-top: 8px;
    }
}

.select-department-popover {
    padding: 12px;
    margin-left: 16px;
    margin-top: 48px;
    z-index: 3;
    position: absolute;
    border-radius: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); 
    background-color: #ffffff;
    width: 300px;
    display: flex;
    flex-direction: column;

    .department-button {
       border: 1px solid #737c84; 
       height: 36px;
       border-radius: 8px;
       padding-left: 20px;
       text-align: left;
       margin-top: 10px;

       &:hover {
        border: 1px solid #0b84ff; 
       }
    }

    .department-dropdown {
        position: absolute;
        width: 92%;
        margin-top: 76px;
        background-color: #ffffff;
        max-height: 320px;
        overflow-y: scroll;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); 

        ul {
            list-style-type: none;
            padding-left: 0px;

            li {
                cursor: pointer;
                width: 100%;
                padding-left: 12px;

                &:hover {
                    background-color: #dddddd;
                }
            }
        }
    }

    .top-row {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .clear-all-btn {
        margin-right: 0px;
    }

        .done-button {
            border: 1px solid #737c84; 
            width: 80px;
            height: 32px;
            border-radius: 8px;
            margin-right: 0px;
            margin-left: auto;
            margin-top: 20px;
        }
}

.arrow-down {
    background-image: url("/icons/down-arrow.svg");
    background-repeat: no-repeat;
    background-position: 92% 50%;
}

.arrow-up {
    background-image: url("/icons/up-arrow.svg");
    background-repeat: no-repeat;
    background-position: 92% 50%;
}