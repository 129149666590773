@import 'stylesheets/_vars.scss';

.login__form-option {
  color: $grey-light;
  text-align: center;
  padding: 10px 0;

  & .login__form-option-divider {
    height: 1px;
    border-top: 1px solid $grey-light;
    width: 15px;
  }
}

.login__form-errors {
  text-align: center;
  margin-top: 25px;
  height: 24px;
}

.login__form-error-text {
  display: none;
  color: $primary-red;

  &.show {
    display: block;
  }
}

.demo-button {
  background-color: red;
}
