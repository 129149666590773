@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';


.shift-row__day-cell {
    border-left: 1px solid #d0d0d4;
    min-width: 164px;
    padding-top:4px;
    padding-left: 4px;
    padding-right: 4px;
    position: relative;
    justify-content: center;
    height: 100%;
}

.top-align {
    vertical-align: top;
}

.middle-align {
    vertical-align: middle;
}

.total-row__owner-cell {
    vertical-align: middle;
    padding-left: 20px;
}

.labor-hours-num {
    color: #2c2c2e;

    @font-face {
        font-family: 'AvertaRegular';
        src: url('/fonts/averta/Averta-Regular.ttf');
    }

    font-family: "AvertaRegular";
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.17px;
    line-height: normal;
}

.position-line {
    font-size: 12px;
    @font-face {
        font-family: 'AvertaLight';
        src: url('/fonts/averta/Averta-Light.ttf');
    }
    max-height: 20px;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 5px;
}

.total-hours-text {
    color: #2c2c2e;
    font-family: 'AvertaSemiBold';
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    letter-spacing: -0.17px;
    line-height: normal;
}


.total-hour-cell {
    margin-top:10px;
    vertical-align: middle;
    padding-left: 20px;
    width: 222px;

    .down-button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
        
        .left-group {
            width: 100%;
            display: flex;
            flex-direction: column; 
            margin-top: 4px;

        .total-text {
            display: flex;
        }

        .total-avatar {
            margin-left: 8px;
        }

        .user-count {
            font-size: 12px;
            display: flex;
        }

        }

        .right-group {
        display: flex;
        flex-direction: row; 
        margin-left: auto;
        margin-right: 12px;
        margin-top: auto;
        margin-bottom: auto;

        .labor-hours-num {
            margin-top: 8px; 
        }

        .down-icon {
            margin-left: auto;
            margin-right: 12px;
            width: 12px;
            height: 12px;
        }
        }
    }
}

ul.total-types {
    overflow-y: hidden;
    overflow: hidden;
    width: 260px;
    bottom: 50px;
    box-shadow: 0 5px 10px 2px rgba(195,192,192,.3);
}
