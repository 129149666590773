.publish-schedule-container {
    max-height: 612px;
    height:fit-content;
    width: 500px;
    position: absolute;
    background-color: white; //rgba(242,242,247,255);
    box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
    z-index: 4; 
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    border-radius: 10px;
    top:0;
    bottom:0;
    right:0;
    left:0;
    margin:auto;
  
    @media (max-width: 800px) {
      left: 50px;
      min-width: 600px;
    }

    .confirm-button {
        height: 34px;
        width: 90px;
        margin-left: 10px;
        border-radius: 7px;
        border: 1px solid rgba(153,156,161,255);
        font-size: 14px;
        font-weight: 400;
        text-align: center;

        color: white;

        &:hover {
            cursor: pointer;
            color: white;
        }
    }

    .confirm-button-cancel{
        height: 34px;
        width: 90px;
        margin-left: 10px;
        border-radius: 7px;
        border: 1px solid rgba(153,156,161,255);
        font-weight: 400;
        text-align: center;
        background-color: rgba(242,242,247,255);

        &:hover {
        cursor: pointer;
        }
    }

    .confirm-button {
        height: 34px;
        width: 90px;
        margin-left: 10px;
        border-radius: 7px;
        border: 1px solid rgba(153,156,161,255);
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        background-color: #0a84ff;

        color: white;

        &:hover {
            cursor: pointer;
            color: white;
        }
    }

    .button-enabled {
        background-color: rgba(11,132,255,255);
        border: 1px solid rgba(11,132,255,255);
        &:hover {
            background-color: rgba(11,132,255,255);
            border: 1px solid rgba(11,132,255,255);
        }
    }

    .button-disabled {
        background-color: #f2f2f7;
        border: 1px solid #f2f2f7;
        color: #6d7278;

        &:hover {
            color: #6d7278;
            background-color: #f2f2f7;
            border: 1px solid #f2f2f7;
        }
    }

    .confirm-button-wrap {
        margin-top:42px;
        margin-right:0px;
        text-align:right;
    }

    .body-text1{
        margin-top:18px;
    }

    .body-text {
        margin-top:24px;
    }

    .title-row {
        justify-content: space-between;
        display: flex;
        .title {
            font-size: 18px;
            @font-face {
                font-family: 'AvertaSemiBold';
                src: url('/fonts/averta/Averta-Semibold.ttf');
              }
              font-family: 'AvertaSemiBold';
        }
    }

    .summary {
        font-size: 14px;
        font-family: 'AvertaSemiBold';
        @font-face {
            font-family: 'AvertaSemiBold';
            src: url('/fonts/averta/Averta-Semibold.ttf');
        }
        color: #2c2c2e;
        margin-top: 15px;
    }

    .total-hours {
        font-size: 14px;
        font-family: 'AvertaRegular';
        @font-face {
            font-family: 'AvertaRegular';
            src: url('/fonts/averta/Averta-Regular.ttf');
        }
        color: #2c2c2e;
        margin-top: 10px;
    }

    .total-shifts {
        margin-top: 10px;
        font-size: 14px;
        font-family: 'AvertaRegular';
        @font-face {
            font-family: 'AvertaRegular';
            src: url('/fonts/averta/Averta-Regular.ttf');
        }
        color: #2c2c2e;
    }

    .conflicts {
        margin-top: 10px;
        font-size: 14px;
        font-family: 'AvertaRegular';
        @font-face {
            font-family: 'AvertaRegular';
            src: url('/fonts/averta/Averta-Regular.ttf');
        }
        color: #2c2c2e;
    }

    .notification-section {
        margin-top: 10px;
        .notif-label {
            font-size: 14px;
            @font-face {
                font-family: 'AvertaSemiBold';
                src: url('/fonts/averta/Averta-Semibold.ttf');
              }
              font-family: 'AvertaSemiBold'; 
        }
    }

    .dropdown {
        margin-top: 10px;
        cursor: pointer;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 8px;
        background-image: url("/icons/down_chev.svg");
        background-repeat: no-repeat;
        background-position: 98% 50%;
        font-size: 14px;
        z-index: 999;
    }
      
    .dropdown-list {
        position: absolute;
        border: 1px solid #ccc;
        background-color: #fff;
        max-height: 150px;
        overflow-y: auto;
        z-index: 1000;
        font-size: 14px;
        width: 440px; 
        border-radius: 8px;
        margin-top: 1px;
    }
      
    .dropdown-list div {
        padding: 10px;
        cursor: pointer;
    }
      
    .dropdown-list div:hover {
        background-color: #daedff;
    }

}