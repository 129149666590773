@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

$exportFontColor: #2c92d5;
$filter-breakpoint: 1010px;

.lower-container {
  padding: 0px 20px 10px;
}

.lower-container--left-half,
.lower-container--right-half {
  display: inline-block;
  min-width: 320px;
  width: 50%;

  @media (max-width: $filter-breakpoint) {
    display: block;
    width: 100%;
  }
}

.lower-container--right-half {
  & .checkbox-group {
    width: 100%;
    text-align: right;
    display: inline-block;

    @media (max-width: $filter-breakpoint) {
      text-align: left;
    }
  }
}

button.btn.btn-primary.btn-export {
  position: relative;
  padding: 6px 15px;
  opacity: 1;
  font-weight: 600;
  font-size: 14px;
  background-color: white;
  color: $primary-blue;
  border: 1px solid $primary-blue;
  border-radius: 20px;
  max-height: 32px;
  margin-right: 8px;

  &:hover {
    background-color: $primary-blue;
    color: white;
  }
}
