@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.group {
  &__wrap {
    padding: 20px;
    border-bottom: 1px solid rgba(227,227,230,255);
    display: flex;
    flex-direction: row;
    height: 64px;
    position: relative;
    //align-items: center;
  }
  &__title {
    height: 100%;
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 14px;
    font-weight: 400;
    //border: 1px solid blue;
  }

  &__close-button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-image: url("/icons/close-comments.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: rgba(242,242,247,255);
    position: absolute;
    top: 14px;
    right: 12px;
    //border: 1px solid red;

    &:hover {
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
    &:focus {
      outline: none;
    }
  }

  &__inner-wrap {
    border-bottom: 1px solid rgba(227,227,230,255);
    display: flex;
    flex-direction: column;
    //height: 64px;
    position: relative;
    padding: 10px;
    align-items: center;
    //border: 1px solid red;
  }

  &__inner-title {
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    height: 20px;
    //border: 1px solid blue;
  }

  &__text {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    //border: 1px solid blue;
  }

  &__description {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 400;
    color: rgba(109,114,120,255);
    //height: 20px;
    text-align: center;
    //border: 1px solid blue;
  }

  &__address {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 400;
    color: rgba(109,114,120,255);
    //height: 20px;
    text-align: center;
    //border: 1px solid blue;
  }

  &__edit-button {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 300;
    height: 20px;
    height: 35px;
    border-radius: 5px;
    border: 1px solid black;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 16px;

    &:hover {
      
    }
    &:focus {
      outline: none;
    }
  }
}

.edit-group {

  &__top-line {
    display: flex;
    flex-direction: column;
    //border: 1px solid red;
  }

  &__title {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 16px;
    font-weight: 400;
    //border: 1px solid red;
  }

   &__info {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 12px;
    font-weight: 400;
    margin-top: 0px;
    //margin-bottom: 20px;
    //border: 1px solid blue;
  }

  &__group-name-wrap {
    display: flex;
    flex-direction: column;
    height: 50px;
    border-radius: 7px;
    padding-top: 3px;
    padding-left: 8px;
    //margin-right: 30px;
    margin-bottom: 10px;
    border: 1px solid rgba(161,161,163,255);
    //border: 1px solid red;
  }

  &__group-description-wrap {
    display: flex;
    flex-direction: column;
    height: 120px;
    border-radius: 7px;
    padding-top: 3px;
    padding-left: 8px;
    //margin-right: 30px;
    margin-bottom: 10px;
    border: 1px solid rgba(161,161,163,255);
  }

  &__caption {
    //height: 20px;
    margin-bottom: 0px;
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    color: rgba(172,175,178,255);
    //border: 1px solid blue;
  }

  &__name {
    //height: 20px;
    margin-right: 8px;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    outline: none;
    border: none;
    //border: 1px solid blue;

    &:hover {
      //border: 1px solid blue;
    }
  }

  &__description {
    //height: 20px;
    margin-right: 8px;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    outline: none;
    border: none;
    resize: none;
    height: 80%;
    //border: 1px solid red;

    &:hover {
      //border: 1px solid blue;
    }
  }

  &__button-wrap-create {
    display: flex;
    flex-direction: row;
    margin-top: 22px;
    margin-left: 142px;
    //border: 1px solid red;
  }

  &__button-cancel {
    height: 34px;
    width: 90px;
    margin-left: 10px;
    border-radius: 7px;
    border: 1px solid rgba(153,156,161,255);
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    //color: white;
    background-color: rgba(242,242,247,255);

    &:hover {
      cursor: pointer;
      //color: white;
      //background-color: rgba(11,132,255,255);
      //border: 1px solid rgba(11,132,255,255);
    }
  }

  &__button {
    height: 34px;
    //width: 90px;
    margin-left: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 7px;
    border: 1px solid rgba(153,156,161,255);
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    //background-color: rgba(242,242,247,255);
    color: white;
    background-color: rgba(11,132,255,255);
    border: 1px solid rgba(11,132,255,255);

    &:hover {
      cursor: pointer;
      color: white;
      background-color: rgba(11,132,255,255);
      border: 1px solid rgba(11,132,255,255);
    }
  }
}
