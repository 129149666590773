.toast-message {
    background-color: #2c2c2e;
    color: #ffffff;
    margin:auto;
    width:fit-content;
    border-radius:16px;
    text-align: center;
    padding-left:24px;
    padding-right:24px;
    padding-top:6px;
    padding-bottom:6px;
    font-size:14px;
    position:absolute;
    bottom:60px;
    margin-left:auto;
    margin-right:auto;
    left:0;
    right:0;
}