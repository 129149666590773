


.user-header {
    display:flex;
    flex-direction: row;

    img {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        margin-top: 2px;
    }

    .user-wrap {
        margin-left: 8px;
        .name-wrap {
           display: flex; 
           height: 14px;
            .name {
                font-family: 'AvertaSemiBold';
                font-size: 14px;
            }
        }

        .job-title {
            font-family: 'AvertaRegular';
            font-size: 12px;
            margin-top: 0px;
        }
    }
}
