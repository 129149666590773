@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

$padLeft: 20px;

.dotted-border {
  border-style: dashed;
  border-width: 2px;
  min-height: 56px;
  background-color: #DAEDFF ;
}

.solid-border {
  border-style: solid;
  background-color: #DAEDFF ;
}

.add-shift-wrap {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  min-height: 48px;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%; 
}

.day-cell {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;  /* Ensure it takes up the full height of its parent */
  border-color: #0A84FF;
  border-width: 2px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  padding: 8px;
  justify-content: center; 
 
  &__topWrap {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 2px;
    height: 34px;
    margin-left: 16px;
    width: 119px;
  }

  &__time-range {
    letter-spacing: -0.26px;
    line-height: normal;
    min-height: 17px;
  }

  &__job-hour {
    display: flex;
    flex-direction: row;
    height: 20px;
  }

  .top-row {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    margin-top: 2px;

    .time-label{
      letter-spacing: -0.26px;
      line-height: normal;
      min-height: 17px;
      @font-face {
        font-family: 'AvertaSemiBold';
        src: url('/fonts/averta/Averta-Semibold.ttf');
      }
      font-family: 'AvertaSemiBold';
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
      margin-top: 0px;
      cursor: pointer;
    }

    img {
      margin-top: -2px;
      margin-right: 4px;
    }
  }


}

.bottom-line-one-text-item {
@font-face {
  font-family: 'AvertaLight';
  src: url('/fonts/averta/Averta-Light.ttf');
}
font-family: 'AvertaLight';
font-size: 12px;
font-weight: 400;
margin: auto;
min-width: 140px;
max-width: 180px;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
height: 15px;
//border: 1px solid green;
}


.mouse-tip {

  &__top-wrap {
    margin-top: 1px;
    margin-left: 15px;
    margin-right: 15px;
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 600;
    //border: 1px solid red;
  }
  
  &__wrap {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-right: 15px;
    //border: 1px solid red;
  }

  &__position {
    @font-face {
      font-family: 'AvertaLight';
      src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 400;
    color: black;
    //border: 1px solid blue;
  }
}

.invite-user-phone-entry {
  align-items: flex-start;
  border: 1.3px solid;
  border-radius: 10.4px;
  display: flex;
  overflow: hidden;
  padding: 9.7px 14.7px;
  width: 258px;
}

.plus_sign {
  height:18px;
  width:24px;
  margin-right:0px;
  margin-left: auto;
  margin-top: -10px;
  @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-size:14px;
  text-align:center;
}

.corner_menu {
  background-color: aqua; 
  position: absolute;
  right:-70px;
  top:20px;
  z-index:3;
  padding:5px;
  min-width:70px;
}

.day-cell-top-wrap {
  display: flex;
  flex-direction: row;
  height: 58px;
  min-width: 156px;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 6px;
  cursor: pointer;
  transform: translate(0, 0);
}

.status-tag {
  position: absolute; 
  font-size: 9.5px;
  z-index: 5;
  top: -6px;
  right: 10px;
  color: #ffffff;
  font-weight: 300;
  font-family: 'AvertaSemibold';
  border-radius: 8px;
  padding-top:2px;
  padding-left:4px;
  padding-right:4px;
  padding-bottom:0px;
}

.not-scheduled-wrap {
  font-size: 12px;
  font-weight: 400;
  @font-face {
    font-family: 'AvertaSemibold';
    src: url('/fonts/averta/Averta-Semibold.ttf');
  }
  font-size: 16px; 
  color: #bbbbbe;
  margin: auto;
  cursor: pointer;
  border: 1px solid #cccccf;
  padding: 5px 10px 5px 10px;
  border-radius: 6px;
}

.card_called_off {
  text-decoration: line-through;
  .status-tag {
    background-color: #FF4650;
  }

  .day-cell {
    border-color: #8E8E93;
    background-color: #F2F2F7;
  }
}

.card_posted {
  .status-tag {
    background-color: #0a84ff;
  }
}

.card_approved {
  .status-tag {
    background-color: #34C759;
  }
}

.card_covered{
  .status-tag {
    background-color: #34C759;
  }
}

.card_pending {
  .status-tag {
    background-color: #FF9500;
  }
}

.conflict-sign {
  cursor: pointer;
}

.shift-row__day-cell .hover-add {
  position: absolute;
  visibility: visible;
  background-color: #ffffff;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 2;
  font-size: 12px;
  border-radius: 12px;
  border: 1px solid #2c2c2e;
  padding-left: 12px;
  padding-right: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: inline-block;
  visibility: visible; 
  cursor: pointer;
  transition: visibility 0.3s ease;
  bottom: 1px;
  height: 20px;
  width: 48px;
  text-align: center;
}

.dotted-drop-frame {
  display: flex;
  position: absolute;
  border-style: dashed;
  border-width: 1px;
  border-color: #2c2c2e;
  border-radius: 8px;
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
  margin: auto;

  display: flex;
  flex-direction: row;
  min-width: 156px;
  align-items: center;
  cursor: pointer;
  transform: translate(0, 0);
}


.head-cell {
  display: flex;
  flex-direction: row;
  height: 30px;
  margin:auto;
  vertical-align: middle;
  margin-top: 12px;
  margin-bottom: 12px;
  min-height: 44px;

  .os-icon {
    vertical-align: middle;
    height: 32px;
    width: 32px;
    margin-top: 8px;
  }

  .right-column {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    margin-top: 4px;
  }

  .os-label {
    font-size: 14px;
    font-family: 'AvertaSemibold';
  }

  .os-hours {
    font-size: 12px;
    font-family: 'AvertaRegular';
  }
}

.quantity-bubble {
  color: #ffffff;
  background-color: #2c2c2e;
  border-radius: 8.5px;
  font-size: 10px;
  min-width: 17px;
  min-height: 17px;
  padding-top: 1px;
  text-align: center;
  vertical-align: middle;
  font-family: 'AvertaSemibold';
  margin-right: 4px;
  padding-left: 3px;
  padding-right: 3px;
}

.position-hours {
  @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 12px;
  font-weight: 400;
  height: 15px;
  padding-top: 0;
  text-overflow: ellipsis;
  max-width: 100px;
  white-space: nowrap;
  overflow-x:hidden;
  overflow-y:hidden;
  cursor: pointer;
}

.left-margin-4 {
  margin-left: 4px;
}