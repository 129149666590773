

.invite-team-member-container{
    width:420px;
    flex-direction: column;

    .explain-row {
        margin-top: 10px;
    }
    
    .phone-row {
        margin-top: 20px;
    }

    .confirm-button-wrap {

        display: flex; 
        
        .confirm-button-cancel {
            color: #ffffff;
            background-color: #0a84ff;
            border-radius: 8px;
            padding-left: 20px;
            padding-right: 20px;
            padding-top:10px;
            padding-bottom:10px;
            margin-right: 0px;
            margin-top: 10px;
            display:flex;
        }

        .button-disabled {
            background-color: #f2f2f7;
            border: 1px solid #f2f2f7;
            color: #6d7278;

            &:hover {
                color: #6d7278;
                background-color: #f2f2f7;
                border: 1px solid #f2f2f7;
            } 
        }

        .action-btn {
            margin-top: 32px;
            margin-left:auto;
            margin-right:auto;
            display:flex;
        }
    }


    .country-wrap {
        display: flex;
        border-radius:6px;
        border: 1px solid #d9d9d9;
        padding-left: 12px;
        padding-top: 6px;
        padding-bottom: 6px;
        margin-top: 16px;

        input {
            border: none;
            margin-left: 8px;
            margin-top:0px;
            width: 100%;
        }

        input:focus, textarea:focus, select:focus {
            outline-offset: 0px !important;
            outline: none !important;
        }
    }
}

