@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

$padLeft: 20px;

.calendar-table {
    z-index: 1;
    width: 100%;
    border-collapse: collapse;
    margin-top: 4px;
  
    &.loading {
      z-index: -1;
    }
  
    &__wrap {
      position: relative;
      width: 100%;
      display: block;
      height: calc(100vh - 302px);
      overflow-y: scroll;
      border-top: 1px solid #d0d0d4;
    }
  
    &__header-title {
  
      @font-face {
        font-family: 'AvertaSemibold';
        src: url('/fonts/averta/Averta-SemiBold.ttf');
      }
  
      font-family: 'AvertaSemibold';
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: #404040;

      //--------Sticky column header
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: white; //very important! without this, the header is transparent

      cursor: pointer;
      span {
        font-weight: 500;
      }
    }
  
    &__headers {
      text-align: left;
      height: 50px; //makes space between header and body
      border-bottom: 1px solid rgba(208, 208, 212, 255);
    }
  }

  span.weekday-header-selected {
    border: 2px solid #2c2c2e;
    border-radius: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
  }