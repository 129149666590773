.add-location-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    /* Overlay background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.form-contents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 500px;
    margin-top: 60px;
    margin-bottom: 48px;
}

.location-page {
    background: white;
    border-radius: 12px;
    width: 100%;
    max-width: 500px;
    padding: 20px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px; /* Moves it to the right */
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
}

.location-page__header {
    @font-face {
        font-family: 'AvertaSemibold';
        src: url('/fonts/averta/Averta-Semibold.ttf');
    }

    font-family: 'AvertaSemibold';
    font-size: 16px;
    font-weight: 300;
    margin-bottom:0px;
    letter-spacing: -0.33px;
    line-height: 16.17px;
    margin-bottom: 20px;
}

.location-page__header-main {
    @font-face {
        font-family: 'AvertaSemibold';
        src: url('/fonts/averta/Averta-Semibold.ttf');
    }

    font-family: 'AvertaSemibold';
    font-size: 16px;
    font-weight: 300;
    margin-bottom:0px;
    letter-spacing: -0.33px;
    line-height: 16.17px;
    margin-bottom: 20px;
    text-align: center;
    flex-grow: 1;
    margin: 0;
}

.location-page__header-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.location-page__header-container button {
    margin-right: 10px;
}

.location-page__description {
    @font-face {
        font-family: 'AvertaRegular';
        src: url('/fonts/averta/Averta-Regular.ttf');
    }

    font-family: 'AvertaRegular';
    font-size: 12px;
    font-weight: 300;
    margin-bottom:0px;
    letter-spacing: -0.13px;
    margin-top: 20px;
}

.location-page__location-logo {
    width: 56px;
    height: 56px;
    display: block;
    border-radius: 50%;
    /* Ensures the image is treated as a block element */
    margin: 20px auto;
}

.location-page__confirm-description {
    @font-face {
        font-family: 'AvertaRegular';
        src: url('/fonts/averta/Averta-Regular.ttf');
    }

    font-family: 'AvertaRegular';
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    margin-bottom:0px;
    letter-spacing: -0.13px;
    margin-top: 20px;
}

.location-page__confirm-description2 {
    @font-face {
        font-family: 'AvertaRegular';
        src: url('/fonts/averta/Averta-Regular.ttf');
    }

    font-family: 'AvertaRegular';
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    margin-bottom:0px;
    letter-spacing: -0.13px;
}

.location-page__location-name {
    @font-face {
        font-family: 'AvertaSemibold';
        src: url('/fonts/averta/Averta-Semibold.ttf');
    }

    font-family: 'AvertaSemibold';
    font-size: 16px;
    font-weight: 300;
    margin-bottom:0px;
    letter-spacing: -0.33px;
    text-align: center;
    line-height: 16.17px;
    margin-bottom: 10px;
}

.location-page__input_title {
    @font-face {
        font-family: 'AvertaSemibold';
        src: url('/fonts/averta/Averta-Semibold.ttf');
    }

    font-family: 'AvertaSemibold';
    font-size: 12px;
    font-weight: 300;
    margin-bottom:0px;
    color:#2C2C2E;
    letter-spacing: -0.15px;
    margin-top: 20px;
}

.location-page__back-button {
    background: none;
    border: none;
    cursor: pointer;
}

.location-page__back-button img {
    width: 30px;
    height: auto;
}

.location-page__form-group {
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 0px;
    margin-right: 0px;
    max-width: 500px;
}

.location-page__input-location-code {
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    border: 1px solid #6D7278;
    border-radius: 8px;

    @font-face {
        font-family: 'AvertaRegular';
        src: url('/fonts/averta/Averta-Regular.ttf');
    }

    font-family: 'AvertaRegular';
    font-size: 12px;
    font-weight: 300;
    margin-bottom:0px;
    letter-spacing: -0.3px;
    line-height: 16.17px;
}

.location-page__input-location-code.location-page--error {
    border-color: red;
}

.location__input-error {
    color: red;
    margin-top: 5px;

    @font-face {
        font-family: 'AvertaRegular';
        src: url('/fonts/averta/Averta-Regular.ttf');
    }

    font-family: 'AvertaRegular';
    font-size: 12px;
    letter-spacing: -0.17px;
    line-height: 19px;
}

.location__form-action {
    text-align: center;
}

.location__form-submit {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.location__form-submit:hover {
    background-color: #0056b3;
}

.location-page__header,
.location__form-submit {
    text-align: center;
}

.location-page__button-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
    //border: 1px solid red;
}