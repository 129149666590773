.shift-request--outcome {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.shift-request--description {
  font-size: 16px;
}

.shift-request--managed {
  .shift-request--description-text {
    width: 100%;
  }
}
