
.child-card {
    width: 100%;
    border-width: 0px;
    border-style: none;
    display: flex;
    flex-direction: column;

    .pic {
        width: 48px;
        height: 48px;
        margin-top: 24px;
        margin-left: auto;
        margin-right: auto;
    }

    .stat-label {
        font-size: 9px;
        height: 14px; 
        border-radius: 7px;
        background-color: #34C759;
        color: #ffffff;
        width: fit-content;
        margin: auto;
        padding-top: 2px;
        padding-left: 4px;
        padding-right: 4px;
        margin-top: -6px;
    }
}