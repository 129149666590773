
.flex-row {
    flex-direction: row;
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.text-center {
    text-align: center;
}

.br-50{
    border-radius: 50%;
}

.modal-container{
    position:absolute;
    margin:auto;
    top:0;
    bottom:0;
    right:0;
    left:0;
    background-color: #ffffff;
    border-radius: 8px;
    height: fit-content;
    padding-top:18px;
    padding-bottom:24px;
    padding-left:24px;
    padding-right:24px;
    z-index:3;
}