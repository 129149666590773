@import 'stylesheets/bootstrap/_bootstrap-custom.scss';
@import 'stylesheets/_vars.scss';

.main-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  overflow: hidden;
  background-color: $bodyBackgroundColor;
}

.main-body-row {
  display: flex;
  flex-direction: row; 
  height: 100vh; 
  overflow: hidden;
  background-color: white;
}

.horizontal-header {
  width: 100%;
  height: 60px; 
  background-color: #2c2c2e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0px;
  position: fixed;
  top: 0;
  left: 0;
}

.vertical-header {
  width: 60px;
  background-color: #2c2c2e;
  position: fixed;
  top: 60px; 
  left: 0;
  bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

.content-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 60px);
  margin-left: 60px; 
  margin-top: 60px;
  overflow: hidden; 
  box-sizing: border-box;
}

.main-content {
  width: 100%; 
  height: calc(100vh - 60px);;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  font-size: 12px;
  color: $lightFontColor;
  border-top: 1px solid $lightFontColor;
  height: 40px;
  z-index: 3;

  @media (max-width: $shifts-sm) {
    height: auto;
  }
}

.footer-detail--container {
  box-sizing: border-box;
  padding-top: 10px;
  float: right;

  @media (max-width: $shifts-sm) {
    float: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.5rem;
  }
}

.footer-detail--feature-container {
  box-sizing: border-box;
  padding-top: 10px;
  padding-left: 60px;

  @media (max-width: $shifts-sm) {
    float: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.5rem;
  }
}

.footer-detail--link {
  top: 10px;

  & .footer-detail--link-ind {
    color: $lightFontColor;
    border-right: 1px solid $lightFontColor;
    margin-right: 10px;
    padding-right: 15px;

    &:last-child {
      border: none;
      margin-right: 30px;
    }
  }
}