.title-row {
    .title {
        font-family: 'AvertaBold';
        font-size: 18px;
        margin:auto;
        text-overflow: ellipsis;
    }

    .close-button {
        position: absolute;
        right: 14px;
        top: 12px;
    }
}

.assign-to {
    margin-top: 24px;
    font-family: 'AvertaSemibold';
    font-size: 12px;
}

.apply-to {
    margin-top: 24px;
    font-family: 'AvertaSemibold';
    font-size: 12px;
}

.time-row {
    margin-top: 4px;
}

.user_info_row {
    margin-top: 4px;
    border-radius: 6px;
    border-width: 1px;
    border-color: #6D7278;
    border-style: solid;
    padding: 8px;

    img{
        height: 24px;
        width: 24px;
        border-radius: 50%;
    }

    .user_name {
        margin-left: 8px;
    }
}

.time-label {
    font-family: 'AvertaSemibold';
    font-size: 12px;
    margin-right: 4px;
}

.title-label{
    font-family: 'AvertaSemibold';
    font-size: 12px;
    margin-top: 24px;
}

.entry-box {
    margin-left:0px;
    height: 38px;
    padding-left: 10px;
    border: 1px solid #6D7278;
    border-radius: 7px;
    width: 100%;
    margin-top: 4px;
}

.shift-detail-container{
        height:fit-content;
        min-width: 420px;
        width: fit-content;
        position: absolute;
        background-color: white; 
        box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
        z-index: 4; 
        padding-top: 16px;
        border-radius: 10px;
        top:0;
        bottom:0;
        right:0;
        left:0;
        margin:auto;
        max-width: 62%;
        max-height: 84vh;
      
        @media (max-width: 800px) {
          left: 50px;
          min-width: 600px;
        }

    .confirm-button-wrap {
        margin-top:25px;
        margin-right:0px;
        text-align:right;

       
    }
}

.confirm-button {
    min-width: 90px;
    margin-left: 10px;
    border-radius: 7px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;

    color: white;

    &:hover {
        cursor: pointer;
        color: white;
    }
}

.button-enabled {
    background-color: #0a84ff;
    color: #ffffff;
}

.button-disabled {
    background-color: #f2f2f7;
    border: 1px solid #f2f2f7;
    color: #6d7278;

    &:hover {
        color: #6d7278;
        background-color: #f2f2f7;
        border: 1px solid #f2f2f7;
    }
}

.narrow-height {
    height: 34px;
}


.shift-fields-container {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
}


input.rc-time-picker-panel-input {
    border-radius: 7px;
    font-family: 'AvertaRegular';
    font-size: 14px;
    height:38px;
}

input.rc-time-picker-input {
    border-radius: 7px;
    font-family: 'AvertaRegular';
    font-size: 14px;
    height:38px;
    padding-top:6px;
    color: #2c2c2e;
    border-color: #6D7278;
    width: 202px;
}

input.rc-time-picker-input[disabled] {
    color: #2c2c2e;
    background-color: #F2F2F7;
}

.picker-divider {
    width:16px;
    display: inline-block;
}

.rc-time-picker {
    width:fit-content;
 
}

.rc-time-picker-panel-select li {
    font-family: 'AvertaRegular'; 
    font-size: 14px;
}

.rc-time-picker-panel-select-option-selected{
    font-family: 'AvertaBold'; 
    font-size: 14px;
}

.button-row {
    justify-content: space-between; 
    display: flex;
    margin-top: 40px;
}

button.copy-button {
    margin-left: 12px;
    font-family: 'AvertaSemibold';
    font-size: 12px;
    color: #2c2c2e;
    margin-top: 12px;
}

button.delete-button {
    font-family: 'AvertaSemibold';
    font-size: 12px;
    color: #FF3B30;
    margin: auto;
    margin-top: 12px;
}

button.cancel-button {
    width: 108px;
    height: 40px;
    font-family: 'AvertaBold';
    font-size: 14px;
    background-color: #f2f2f7;
    color: #6D7278;
    border-radius: 8px;
    margin-right: 16px;
}

button.save-button {
    border-radius: 8px;
    width: 108px;
    height: 40px;
    background-color: #0a84ff;
    color: #ffffff;
    font-family: 'AvertaBold';
    font-size: 14px;
}

.job-box {
    border-radius: 8px;
    border: 1px solid #6d7278;
    padding-left: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-family: 'AvertaRegular';
    font-size: 14px;
    width: 100%;
    height: 38px;
}

.job-box[disabled] {
    color: #2c2c2e;
    background-color: #ffffff;
}

input.job-box:focus {
    border-color: #0a84ff;
}

.warning {
    color: #FF3B30;
    font-family: 'AvertaRegular';
    font-size: 12px;
}

.overnight-shift-warning {
    margin-top: 8px;
    color: #5b4241;
}

.group-header {
   width: 100%; 
   text-align: left;
   padding-left: 10px;
   padding-top: 10px;
   padding-bottom: 10px;
}

.group-placeholder {
    font-family: 'AvertaSemibold';
    font-size: 12px;
    color: #000000;

    background-image: url("/icons/down-arrow.svg");
    background-repeat: no-repeat;
    background-position: 96% 50%;
    height: 38px;

    border-radius: 8px;
    border: 1px solid #6D7278;

    width: 100%; 
    text-align: left;
    padding-left: 10px;
}

.dropdown-menu {
    max-height: 240px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 280px; 
}

.shift-description {
    width: 100%;
    border-radius: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    min-height: 70px;
    margin-top: 2px;
}

.quantity-input {
    border-radius: 8px;
    border-width: 1px;
    padding-top:4px;
    padding-bottom:4px;
    padding-left: 8px;
}

.top-row {
    display: flex;
}

.assign-to-wrap {
    width: 100%;
    flex-direction: column;
    display: flex;
}

.quantity-wrap {
    display: hidden;
    flex-direction: column;
    margin-left: 14px;
    input {
        width: 100%;
        height: 38px;
    }
}

.shown {
    display: flex;
}

.hidden {
    display: none;
}

.assign-icon {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    margin-right: 4px;
    margin-top: auto;
    margin-bottom: auto;
}

.assign-item {
    padding-left: 8px;
    height: 42px;

    .assign-right {
        display: flex;
        margin-left: 4px;
        justify-content: space-evenly;

        .user_name {
            margin-top: 4px;
            color: #1d1d1f;
            width: 100%;
        }

        .user_position {
            margin-bottom: 4px;
            color: #6D7278;
            text-overflow: ellipsis;
            max-height: 12px;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}

.dropdown-li {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 12px;
    cursor: pointer;

    label {
        width: 100%;
        cursor: pointer;
    }

    .count-li {
        font-size: 12px;
    }
}

.section-tab {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    height: 48px;
    color: #2C2C2E;
    border-top: 1px solid #C7C7CC;
    border-bottom: 1px solid #C7C7CC;
    font-family: 'AvertaSemiBold'; 

    .active {
        color: #0A84FF;
        border-bottom: 2px solid #0a84FF;
        font-family: 'AvertaBold';
    }

    .tab-button {
        font-size: 14px;
        width: 100%;
        height: 100%;
        margin:auto;
    }
}

.disabled {
    background-color: #6D7278;
}

.group-placeholder[disabled] {
    background-color: #F2F2F7;
}

.job-box[disabled] {
    background-color: #F2F2F7;
}

.shift-description[disabled] {
    background-color: #F2F2F7;
}

.mini-avatar {
    margin-left: 4px;
    width: 16px;
    height: 16px;
    border-radius: 50%; 
}

.author-name {
    margin-left: 4px;
    margin-top: 24px;
    margin-right: 4px;
}

.author-tag {
    height: 16px;
    border-radius: 8px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    color: #2C2C2E;
    background-color: #F2F2F7;
    margin-top: 24px;
}

.time-title {
    margin-top: 24px;
}
