@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.group {
  &__wrap {
    padding: 20px;
    border-bottom: 1px solid rgba(227,227,230,255);
    display: flex;
    flex-direction: row;
    height: 64px;
    position: relative;
    //align-items: center;
  }
  &__title {
    height: 100%;
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 14px;
    font-weight: 400;
    //border: 1px solid blue;
  }

  &__close-button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-image: url("/icons/close-comments.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: rgba(242,242,247,255);
    position: absolute;
    top: 14px;
    right: 12px;
    //border: 1px solid red;

    &:hover {
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
    &:focus {
      outline: none;
    }
  }

  &__inner-wrap {
    border-bottom: 1px solid rgba(227,227,230,255);
    display: flex;
    flex-direction: column;
    //height: 64px;
    position: relative;
    padding: 10px;
    align-items: center;
    //border: 1px solid red;
  }

  &__inner-title {
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    height: 20px;
    //border: 1px solid blue;
  }

  &__text {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    //border: 1px solid blue;
  }

  &__address {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 400;
    color: rgba(109,114,120,255);
    //height: 20px;
    text-align: center;
    //border: 1px solid blue;
  }

}
