@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.scheduler {
  
  &__wrap {
    width: 100%;
    padding-top: 0;
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
    background-color: white;
    overflow-x: auto;
    display:inline-table;
  }

  &__title {
    height: 50px;
    padding-top: 10px;
    padding-left: 20px;
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 15px;
    font-weight: 500;
    color: rgba(117,117,117,255);
    border-bottom: 1px solid rgba(208,208,212,255);
    //border: 1px solid blue;
  }

  @media (max-width: 680px) {
    padding-top: 0;
    margin-top: 50px;
  }
  &__main-body {
    height: 75%;
    //border: 1px solid blue;
    overflow:visible;
  }
}