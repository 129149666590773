@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.category {
  

  &__wrap {
    position: relative;
    height: 40px;
    display: flex;
    flex-direction: row;
    padding-top: 0;
    background-color: white;
    border-bottom: 1px solid rgba(208,208,212,255);
    margin-right:0px;
    //border: 1px solid blue;

    @media (max-width: 880px) {
      padding-top: 0;
      margin-top: 50px;
    }
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-left: 30px;
    margin-right: 10px;
    //border: 1px solid red;

    &:hover {
      cursor: pointer;
      color: rgba(0,135,255,255);
      background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
  }

  &__text-wrap-selected {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-left: 30px;
    margin-right: 10px;
    color: rgba(0,135,255,255);
    //border: 1px solid red;

    &:hover {
      cursor: pointer;
      color: rgba(0,135,255,255);
      background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
  }

  &__text {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  &__create-button {
    position: absolute;
    right: 20px;
    height: 35px;
    width: 80px;
    border: 1px solid rgba(115,124,132,255);
    border-radius: 7px;
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    bottom:7px;

    &:hover {
      cursor: pointer;
      outline: none;
    }
    &:focus {
      outline: none;
    }
    //border: 1px solid red;
  }

  &__dropdown-item-wrap {
    width: 110px;
    padding-left: 15px;
    //border: 1px solid red;

    &:hover {
      cursor: pointer;
      //color: rgba(0,135,255,255);
      background-color: rgba(242,242,247,255);
      //border: 1px solid rgba(0,135,255,255);
    }
    
  }

  &__action-dropdown-text {
    margin-top: 6px;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 10px;
    font-weight: 400;
    //border: 1px solid blue;
  }
  
}