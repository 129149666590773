.settings {
    &_big-wrap {
        width:100%;
        height: 100%;
        background-color: #F2F2F7;

        .toast-message {
            background-color: #2c2c2e;
            color: #ffffff;
            // display:flex;
            margin:auto;
            width:fit-content;
            border-radius:16px;
            text-align: center;
            padding-left:24px;
            padding-right:24px;
            padding-top:6px;
            padding-bottom:6px;
            font-size:14px;
            position:absolute;
            bottom:60px;
            margin-left:auto;
            margin-right:auto;
            left:0;
            right:0;
        }
    }

    &_title {
        height: 50px;
        padding-top: 12px;
        padding-left: 20px;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 500;
        color: #2C2C2E;
        border-bottom: 1px solid #c7c7cc;
        margin-top:4px;
    }

    &_row {
        margin:auto;
        display:flex;
        width:fit-content;
    }

    &_list {
        flex-direction: column;
        width:fit-content;
        display:inline-flex;
        vertical-align: top;
        margin-top: 92px;
        padding-right: 24px;

        ul li {
            font-size: 18px;
            margin-bottom:12px;
            text-align: right;
            cursor: pointer;
            list-style: none;
            font-family: 'AvertaSemibold';
        }

        .active-li {
            color: #0A84FF;
            text-decoration: underline;
        }
    }

    &_page-container {
        width:fit-content;
        display:inline-flex;
    }
}