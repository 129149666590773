.comment-row {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 20px;

    .info-line {
        margin-left: 16px;

        .name {
            font-family: 'AvertaSemiBold';
        }

        .timestamp {
            margin-left: 4px;
            font-size: 12px;
            color: #6D7278;
            font-family: 'AvertaRegular';
        }
    }

    .message {
        margin-top: -2px;
        margin-left: 16px;
        color: #2c2c2e;
        font-family: 'AvertaRegular';
    }
}

.comments-header {
    display: flex;
    flex-direction: row;
    padding-top:16px;
    padding-bottom:16px;
    border-bottom: 0.5px solid #6D7278;
}

.comments-list {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 8px;
    overflow-y: scroll;
    max-height: calc(100vh - 440px);
}

.comment-box{
    padding: 8px;
    border: 1px #979797 solid;
    border-radius: 6px;
    margin-bottom: 24px;
    margin-left: 24px;
    margin-right: 24px;
    display: flex;
    flex-direction: column;
}

.comment-input {
   color: #2C2C2E;
   outline: none;
   border-width: 0px;

   &:focus {
       outline: none;
   }
}

img.avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-bottom: auto;
}

.applicant-info {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    font-size: 14px;

    .name-position {
        font-family: 'AvertaSemiBold';
        color: #1D1D1F;
    }

    .tagline {
        color: #6D7278;
        font-family: 'AvertaRegular';
    }
}

.back-button {
    padding-left: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 20px;
    cursor: pointer;
}

.send-button {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-left: auto;
}

.avatar {
    width: 40px;
    height: 40px;
}