.button-mouse-tip {
  &__wrap {
    min-width: 100px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    background-color: rgba(44,44,46,255);
    display: flex;
    flex-direction: column;
    //box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
    //border: 1px solid red;
  }
  &__text {
    min-width: 70px;
    text-align: center;
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 600;
    color: white;
    white-space: nowrap;
  }
  &__triangle {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background-color: transparent;
    border-style: solid;
    border-left-width: 5px;
    border-right-width: 5px;
    border-top-width: 10px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    //transform: rotate(180deg);
  }
}

.menu {
  &__wrap {
    min-width: 200px;
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    //border: 1px solid blue;
  }

  &__dropdown-item-wrap {
    padding-left: 10px;
    padding-right: 10px;
    //border: 1px solid red;

    &:hover {
      cursor: pointer;
      background-color: rgba(242,242,247,255);
    }
  }

  &__action-dropdown-text {
    //margin-top: 6px;
    padding-top: 7px;
    text-align: left;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 400;
    //border: 1px solid blue;
  }

   &__action-dropdown-text-red {
    //margin-top: 6px;
    padding-top: 7px;
    text-align: left;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 400;
    color: red;
    //border: 1px solid blue;
  }
}