.delete-confirm-container {
    max-height: 612px;
    height:fit-content;
    width: 500px;
    position: absolute;
    background-color: white; //rgba(242,242,247,255);
    box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
    z-index: 4; 
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    border-radius: 10px;
    top:0;
    bottom:0;
    right:0;
    left:0;
    margin:auto;
  
    @media (max-width: 800px) {
      left: 50px;
      min-width: 600px;
    }


    .button-enabled {
        background-color: rgba(11,132,255,255);
        border: 1px solid rgba(11,132,255,255);
        &:hover {
            background-color: rgba(11,132,255,255);
            border: 1px solid rgba(11,132,255,255);
        }
    }

    .button-disabled {
        background-color: #f2f2f7;
        border: 1px solid #f2f2f7;
        color: #6d7278;

        &:hover {
            color: #6d7278;
            background-color: #f2f2f7;
            border: 1px solid #f2f2f7;
        }
    }

    .last-row-wrap {
        display: flex;
        margin-top:42px;
        text-align: right;
        justify-content: flex-end; 

        .right-group {
            display: flex;
            text-align: right;
            justify-content: flex-end; 

            .confirm-button-cancel{
                height: 40px;
                width: 148px;
                border-radius: 7px;
                font-weight: 400;
                text-align: center;
                background-color: rgba(242,242,247,255);

                &:hover {
                cursor: pointer;
                }
            }

            .confirm-button {
                margin-left: 20px;
                height: 40px;
                width: 148px;
                border-radius: 7px;
                font-size: 14px;
                font-weight: 400;
                text-align: center;

                color: white;

                &:hover {
                    cursor: pointer;
                    color: white;
                }
            }

        }
    }

    .body-text1{
        margin-top:18px;
    }

    .body-text {
        margin-top:24px;
    }
}