@import 'stylesheets/_vars.scss';

.upper-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.week-picker {
  margin-right: 12px;
  display: flex;
  align-items: center;
  text-align: left;
  margin-left: 24px;
  margin-top: 18px;
}

.week-picker--current-date-range {
  border-radius: 8px;
  border-width: 1px;
  border-color: #6D7278;
  border-style: solid;
  padding: 8px;
  text-align: center;

  font-size: 14px;
  margin-left:0px;
  margin-right:0px;
  margin-right: 12px;
  float: left;
  word-wrap: break-word;
  min-width:205px;
  font-weight: 300;
  font-family:'AvertaSemibold';
  color: $darkFontColor;
}

.week-picker--left-arrow {
  margin-right:0px;
}

.week-picker--button {
  background: none;
  border: none;
  cursor: pointer;
  outline:none;

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }

}

.today-button {
  height: 38px;
  width: 80px;
  border: 1px solid #6D7278;
  padding: 6px;
  border-radius: 8px;
  margin-right: 16px;
  font-family:'AvertaSemibold';
  font-size: 14px;
  color: #2C2C2E;
}