.split-container {
    display: flex;
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    flex-direction: row;
    /* Ensure side-by-side layout */
}

.logo-container {
    display: flex;
    position: absolute;
    left: 18px;
    top: 16px;
    z-index: 10;

    img {
        width: 153px;
        height: 46.44px;
    }
}

.split {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    /* Keep left and right halves side by side */
}

.left-half {
    background-color: white;
    color: #2C2C2E;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-top: 70px;
    /* Push the content below the logo */
    height: auto;
    overflow-y: auto;
    /* Allow scrolling if content exceeds */
}

.right-half {
    background-color: #2C2C2E;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    height: auto;

    .stars-icon {
        position: absolute;
        left: 50px;
        top: 60px;
        width: 194px;
        height: 194px;
    }

    .shyft-icon {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 194px;
        height: 194px;
    }
}

.form-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 500px;
    margin-top: 60px;
    margin-bottom: 48px;
}

.welcome-text-wrapper {
    display: flex;
    align-items: center;
}

.welcome-text {
    font-family: 'AvertaSemibold';
    font-size: 12px;
    letter-spacing: -0.15px;
    line-height: 20px;
    color: white;
    padding: 2px 8px;
    border-radius: 20px;
    position: relative;
    display: inline-block;
    background: linear-gradient(to left, #000000 0%, #5E5E5E 49.16%, #000000 100%);
    background-size: 200% 100%;
    // animation: shimmer 3s linear infinite;
}

@keyframes shimmer {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}

.dot {
    width: 13px;
    height: 13px;
    background-color: #0A84FF;
    border-radius: 50%;
    margin-right: 10px;
}

.main-heading {
    text-align: left;
    font-size: 44px;
    margin-top: 20px;
    line-height: 53px;
    letter-spacing: -0.53px;
    font-family: 'AvertaBold';
}

.sub-heading {
    text-align: left;
    font-size: 18px;
    margin-top: 2px;
    margin-bottom: 44px;
    line-height: 27px;
    font-family: 'AvertaRegular';
}

.trial-prompt {
    font-family: 'AvertaRegular';
    font-size: 18px;
    letter-spacing: 0.21px;
    line-height: 27px;
    margin-top: 4px;
}

.name-prompt {
    font-family: 'AvertaSemiBold';
    font-size: 18px;
    letter-spacing: 0.21px;
    line-height: 27px;
    margin-top: 36px;
}

.name-input {
    padding: 10px;
    width: 100%;
    min-height: 48px;
    margin-top: 8px;
    border: 1.3px solid #6D7278;
    border-radius: 8px;
    box-sizing: border-box;
    outline: none;
    font-size: 18px;
    letter-spacing: 0.21px;
    line-height: 27px;
    font-family: 'AvertaRegular';
    /* Default background color */
    background-color: #fff;
    transition: all 0.3s ease;

    /* Smooth transition for hover effects */
    &:focus:not(.input-error) {
        border-color: #0A84FF;
    }
}

.name-input:hover:not(.input-error) {
    border-color: #0A84FF;
    /* Blue border on hover if there's no error */
}

.name-input-container {
    width: 100%;
    position: relative;
}

.confirm-location-container {
    flex-direction: row;
    align-items: start;
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
}

.confirm-location-avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    /* Container size */

    img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        object-fit: contain;
        object-position: center;
    }
}

.confirm-location-title {
    font-size: 18px;
    line-height: 27px;
    font-family: 'AvertaSemiBold';
    color: #2C2C2E;
}

.confirm-location-info-div {
    display: flex;
    margin-left: 16px;
    flex-direction: column;
}

.suggestion-item {
    padding: 10px;
    cursor: pointer;
    // border-bottom: 1px solid #ddd;

    font-size: 18px;
    letter-spacing: 0.21px;
    line-height: 27px;
    font-family: 'AvertaRegular';
}

.suggestion-item:hover {
    background-color: #DAEDFF;
}

.suggestion-item strong {
    font-size: 18px;
    letter-spacing: 0.21px;
    line-height: 27px;
    font-family: 'AvertaBold';
}


.next-btn {
    padding: 10px;
    width: 100%;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #0A84FF;
    color: white;
    cursor: pointer;
    margin-top: 32px;
    font-family: 'AvertaSemiBold';
    font-size: 18px;
    letter-spacing: -0.23px;
    border: none;
    min-height: 48px;
}

.next-btn:disabled {
    background-color: #B0BEC5;
    /* Light gray */
    color: #FFFFFF;
    /* Keep text white */
    cursor: not-allowed;
    /* Show disabled cursor */
    opacity: 0.6;
    /* Reduce opacity */
}

.clear-btn {
    padding: 10px;
    width: 100%;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: white;
    color: black;
    cursor: pointer;
    margin-top: 32px;
    font-family: 'AvertaSemiBold';
    font-size: 18px;
    letter-spacing: -0.23px;
    border: 2px solid #6D7278;
    min-height: 48px;
}

.icon-input-container {
    width: 100%;
    position: relative;
}

.icon-name-input {
    padding: 10px 40px 10px 40px;
    width: 100%;
    min-height: 48px;
    margin-top: 8px;
    border: 1.3px solid #6D7278;
    border-radius: 8px;
    box-sizing: border-box;
    outline: none;
    font-size: 18px;
    letter-spacing: 0.21px;
    line-height: 27px;
    font-family: 'AvertaRegular';

    /* Default background color */
    background-color: #fff;
    transition: all 0.3s ease;
    /* Smooth transition for hover effects */

    &:focus:not(.input-error) {
        border-color: #0A84FF;
    }
}

.icon-name-input:hover:not(.input-error) {
    border-color: #0A84FF;
    /* Blue border on hover if there's no error */
}

.icon-name-input:disabled:hover {
    pointer-events: none;
    /* Prevents hover interactions */
    background-color: #f0f0f0;
    /* Optional: Adjust appearance */
    cursor: not-allowed;
    /* Optional: Indicate it's disabled */
}

.input-icon-right {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: 4px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.input-icon-left {
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: 4px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    pointer-events: none;
}

.password-requirements ul {
    list-style-type: none;
    padding: 0;
}

.password-requirements li {
    color: red;
}

.password-requirements li.valid {
    color: green;
}

.checkbox-container {
    display: flex;
    align-items: start;
    margin-top: 24px;
}

.checkbox-input {
    margin-top: 4px;
    width: 18px;
    height: 18px;
}

.checkbox-label {
    font-size: 18px;
    color: #2C2C2E;
    margin-left: 8px;
    font-family: 'AvertaSemibold';
    display: flex;
    letter-spacing: 0.21px;
    line-height: 27px;
    align-items: center;
}

.error-message {
    color: #FF4650;
    font-family: 'AvertaRegular';
    font-size: 14px;
    letter-spacing: 0.21px;
    line-height: 27px;
    margin-top: 5px;
}

.input-error {
    border: 1px solid #FF4650;
}

.login-message {
    font-size: 18px;
    color: #2C2C2E;
    margin-top: 16px;
    text-align: start;
    font-family: 'AvertaSemibold';
    letter-spacing: -0.09px;
    line-height: 27px;

    a {
        color: #0A84FF;
        font-family: 'AvertaSemibold';
        text-decoration: none;
        font-size: 18px;
        letter-spacing: 0.21px;
        line-height: 27px;
    }

    a:hover {
        text-decoration: underline;
    }
}

.login-message-link {
    color: #0A84FF;
    font-family: 'AvertaSemibold';
    text-decoration: none;
    font-size: 18px;
    letter-spacing: 0.21px;
    line-height: 27px;

    &:hover {
        text-decoration: none;
    }
}

.terms-message {
    font-size: 14px;
    color: #2C2C2E;
    margin-top: 16px;
    text-align: center;
    font-family: 'AvertaRegular';
    letter-spacing: -0.18px;
    line-height: 23px;

    a {
        color: #0A84FF;
        font-family: 'AvertaSemibold';
        text-decoration: none;
        font-size: 14px;
        letter-spacing: -0.18px;
        line-height: 23px;
    }

    a:hover {
        text-decoration: underline;
    }
}

/* Loading modal */
.loading-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loading-dialog {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #0A84FF;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

.loading-text {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
}

.country-selector {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: 4px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    cursor: pointer;
}

.country-flag {
    width: 20px;
    height: 20px;
    margin-right: 2px;
}

.dropdown-arrow {
    width: 10px;
    height: 10px;
    margin-left: 5px;
}

/* Country dropdown styles */
.country-dropdown {
    position: absolute;
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 100;
    max-height: 240px;
    overflow-y: auto;
    width: 100%;
    margin-top: 4px;
    border-radius: 8px;
}

.country-option {
    padding: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;

    span {
        margin-left: 8px;
        color: #000000;
        font-family: 'AvertaRegular';
        font-size: 18px;
        letter-spacing: 0.21px;
        line-height: 27px;
    }
}

.country-option:hover {
    background-color: #DAEDFF;
}

.country-option .country-flag {
    width: 20px;
    height: 20px;
    margin-right: 16px;
}




@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Responsive design adjustments */

@media screen and (max-width: 768px) {
    .split-container {
        flex-direction: column;
    }

    .left-half,
    .right-half {
        flex: 1;
        width: 100%;
        height: auto;
        padding-left: 10px;
        padding-right: 10px;
    }

    .right-half {
        display: none;
    }

    .form-content {
        margin-top: 20px;
        padding: 20px;
        margin-bottom: 0px;
    }
}