@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.schedule-tab {

    &_wrap {
        width: calc(100% - 60px);
        padding-top: 0;
        margin-top: 10px;
        margin-left: 0;
        margin-right: 0;
        background-color: white;
        overflow-x: auto;
        display:inline-table;
    }

    &_list {
        flex-direction: row;
        display:inline-block;
        height: fit-content;
        width:flex-grow ;

        ul li {
            display: inline-block;
            font-size: 18px;
            margin-bottom:4px;
            margin-top:8px;
            text-align: left;
            cursor: pointer;
            list-style: none;
            font-family: 'AvertaRegular';
            margin-right: 24px;
        }

        .active-li {
            color: #0A84FF;
            text-decoration: underline;
            font-family: 'AvertaSemibold';
        }
    }
}