@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.posts {
  &__wrap {
    border-bottom: 1px solid rgba(227,227,230,255);
    display: flex;
    flex-direction: row;
    height: 64px;
    position: relative;
    align-items: center;
    //border: 1px solid red;
  }

  &__info-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    //border: 1px solid red;
  }

  &__top-line {
    display: flex;
    flex-direction: row;
    height: 50%;
    //border: 1px solid green;
  }

  &__title {
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    height: 20px;
    //border: 1px solid blue;
  }

  &__text {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 400;
    color: rgba(109,114,120,255);
    height: 20px;
    //border: 1px solid blue;
  }

   &__button-more {
    width: 20px;
    height: 20px;
    background-image: url("/icons/ellipses-icon-h.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: absolute;
    right: 20px;
    
    &:hover {
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
    &:focus {
      outline: none;
    }
  }
}

.confirm {
  &__title {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 24px;
    //border: 1px solid red;
  }
  &__button-wrap {
    display: flex;
    flex-direction: row;
    margin-top: 14px;
    margin-left: 150px;
    margin-bottom: 20px;
    //border: 1px solid red;
  }
  &__button-cancel {
    height: 34px;
    width: 90px;
    margin-left: 10px;
    border-radius: 7px;
    border: 1px solid rgba(153,156,161,255);
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    //color: white;
    background-color: rgba(242,242,247,255);

    &:hover {
      cursor: pointer;
      //color: white;
      //background-color: rgba(11,132,255,255);
      //border: 1px solid rgba(11,132,255,255);
    }
  }

  &__button-leave {
    height: 34px;
    min-width: 90px;
    margin-left: 10px;
    border-radius: 7px;
    border: 1px solid rgba(153,156,161,255);
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: white;
    background-color: rgba(11,132,255,255);
    border: 1px solid rgba(11,132,255,255);
    padding-left: 10px;
    padding-right: 10px;
    
    &:hover {
      cursor: pointer;
      color: white;
      background-color: rgba(11,132,255,255);
      border: 1px solid rgba(11,132,255,255);
    }
  }

  &__text {
    margin-top: 3px;
    height: 20px;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    overflow: visible;
    white-space: nowrap;
    //border: 1px solid blue;
  }
}
