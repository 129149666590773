.top-tab {

    &_wrap {
        width: 100%;
        padding-top: 0;
        margin-top: 10px;
        margin-left: 0;
        margin-right: 0;
        background-color: white;
        overflow-x: auto;
    }

    &_list {
        flex-direction: row;
        display:inline-block;
        height: fit-content;
        width:flex-grow ;

        ul {
            margin-bottom: 0px;
        }

        ul li {
            display: inline-block;
            font-size: 16px;
            margin-bottom:0px;
            margin-top:8px;
            text-align: left;
            cursor: pointer;
            list-style: none;
            font-family: 'AvertaRegular';
            margin-right: 24px;
            margin-bottom:-1px;
        }

        .active-li {
            color: #0A84FF;
            font-family: 'AvertaSemibold';
            border-bottom-color: #0A84FF;
            border-bottom-width: 3px;
            border-bottom-style: solid;
            padding-bottom: 16px;
        }
    }
}