.shift-applicants-container {
    height:fit-content;
    min-width: 420px;
    width: fit-content;
}

.action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
}

.applicant-row-header {
    margin-top: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom:4px;
}

.applicant-row {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
}

.left-side {
    display: flex;
    flex-direction: row;

    .avatar {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .applicant-info {
        margin-left: 8px;
        flex-direction: column;
        display: flex;
        margin-top: 16px;
        margin-bottom: 16px;
        cursor: pointer;

        .name-position{
            font-family: 'AvertaSemibold';
            font-size: 14px;
            cursor: pointer;
        }

        .tagline {
            font-family: 'AvertaRegular';
            font-size: 14px;
            cursor: pointer;
        }
    }
}

.right-side {
   display: flex; 
   vertical-align: middle;
    .conflict-icon {
        height: 21px;
        width: 21px;
        margin-top: auto;
        margin-bottom: auto;
    }

    .chat-icon{
        margin-left: 8px;
        height: 24px;
        width: 24px;
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
    }
}

.deny-button {
    background-color: #F2F2F7;
    border-radius: 4px;
    width: 106px;
    height: 40px;
    font-family: 'AvertaBold';
}

.approve-button {
    font-family: 'AvertaBold';
    background-color: #34C759;
    color: #ffffff;
    border-radius: 4px;
    height: 40px;
    width: 300px;
    margin-left: 12px;
}

.highlighted {
    background-color: #DAEDFF;
}
