.calendar {
  padding-top: 25px;
  padding-bottom: 35px;
  overflow: auto;
  width:100%;

  @media (max-width: 680px) {
    padding-top: 0;
    margin-top: 50px;
  }
}
