@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.top-line {
  
  &__wrap {
    //width: calc(100% - 60px);
    width: 100%;
    height: 56px;
    background-color: white;
    display: flex;
    flex-direction: row;
    //align-items: center;
    //justify-content: center;
    border-bottom: 1px solid rgba(227,227,230,255);
    //border: 1px solid red;
  }

  &__title {
    margin-top: 13px;
    margin-left: 20px;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 15px;
    font-weight: 400;
    //border: 1px solid blue;
  }

  &__button-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 20px;
    margin-top: 2.5px;
    width: 160px;
    //border: 1px solid red;
  }

  &__search-button {
    width: 20px;
    height: 20px;
    margin-right: 40px;
    background-image: url("/icons/search-icon.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
    &:focus {
      outline: none;
    }
  }

  &__question-button {
    width: 20px;
    height: 20px;
    margin-right: 40px;
    background-image: url("/icons/question-mark.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
    &:focus {
      outline: none;
    }
  }

  &__plus-button {
    width: 40px;
    height: 40px;
    //border-radius: 6px;
    //border: 1px solid rgba(115,124,132,255);
    background-image: url("/icons/plus-button.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
    &:focus {
      outline: none;
    }
  }
}
