

.pill-tags {
    margin-top: 24px; 
    justify-content:space-between;
    width: 100%;
    display: flex;

    .pill-item {
        border: 1px solid #2c2c2e;
        padding: 3px;
        border-radius: 13px;
        height: 26px;
        display: flex;

        .count {
            display: flex;
            border-radius: 50%;
            text-align: center;
            height: 20px;
            min-width: 20px;
            background-color: #2c2c2e;
            color: #ffffff;
            vertical-align: middle;
            text-align: center;
            padding-top: 2px;
            padding-left: 6px;
            padding-right: 6px;
        }

        .label {
            margin-left: 3px;
            margin-right: 3px;
            margin-top: 1px;
        }
    }
}