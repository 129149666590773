@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

$padLeft: 20px;

tr.avail-row {
    border-bottom: 1px solid #d0d0d4;

    .avail-user-cell {
        padding-left: 20px;
        min-width: 140px;
        padding-top:6px;
        padding-bottom:6px;

        td {
          border-width: 0;
        }

        .avatar {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            margin-right: 8px;
        }
        span {
          font-size: 14px;
          font-weight: 200;
        }
        span.title-position {
          display:flex;
          flex-direction: column;
          min-width:146px;
        }

        span.user-position {
          max-width: 144px;
        }
    }

    .weekday-cell {
        border-left: 1px solid #d0d0d4;
        min-width: 160px;
        text-align: left;
        padding: 0px;
        vertical-align: top;
        cursor: pointer;
        font-size: 14px;
        font-family: 'AvertaSemibold';

        .avail-element {
            display: block;

            span.avail-time {
              font-family: 'AvertaRegular';
              font-size: 13px;
            }
        }
        span {
            padding-top: 6px;
            padding-bottom: 4px;
        }

        span.prefer-to-work {
            background-color: #E1F7e7;
            color: #2c2c2e;
            border: 2px solid #34C759;
            margin: 4px;
            border-radius: 8px;
            padding-left: 8px;
        }

        span.unavailable {
            background-color:#f2f2f7;
            border: 2px solid #6D7278;
            border-radius: 8px;
            padding-left: 8px;
            margin: 4px;
        }

        span.unsubmitted {
            color: #BABABA;
            height:52px;
            padding:0px;
            align-content:center;
            text-align: center;
            vertical-align: middle;
        }
    }

    .timestamp {
      padding-left: 10px;
      padding-right: 10px;
    }

    td {
      border: 1px solid #d0d0d4;
    }
}

.avail-detail-table {
  border-collapse: collapse;
  .avail-row {
      .user-cell{
        padding-right: 16px;
      }
      .effective-time {
        font-size: 14px;
      }
      .submitted-time {
        font-size: 14px;
      }
  }
}

.avail-table {
    z-index: 1;
    width: 100%;
    border-collapse: collapse;
  
    &.loading {
      z-index: -1;
    }
    
    &__wrap {
      position: relative;
      width: 100%;
      display: block;
      height: calc(100vh - 230px);
      overflow-y: scroll;
      margin-top: 10px;
    }
  
    &__header-title {
      padding-left: $padLeft;
  
      @font-face {
        font-family: 'AvertaLight';
        src: url('/fonts/averta/Averta-Light.ttf');
      }
  
      font-family: 'AvertaLight';
      font-size: 16px;
      font-weight: 400;
      color: #404040;
      //--------Sticky column header
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: white; //very important! without this, the header is transparent
    }
  
    &__headers {
      text-align: left;
      height: 50px; //makes space between header and body
      border-bottom: 1px solid rgba(208, 208, 212, 255);
    }
  }

  .avail-detail-container {
    max-height: 612px;
    height:fit-content;
    min-width: 420px;
    width: fit-content;
    position: absolute;
    background-color: white; 
    box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
    z-index: 4; 
    padding-top: 24px;
    padding-left: 30px;
    padding-right: 20px;
    padding-bottom: 30px;
    border-radius: 10px;
    top:0;
    bottom:0;
    right:0;
    left:0;
    margin:auto;
    overflow-y: scroll;
  
    @media (max-width: 800px) {
      left: 50px;
      min-width: 600px;
    }
  }
  
  .title-view {
    justify-content: space-between;
    display: flex;
  }

  .user-header {
    margin-top: 8px;
  }

  td.avail-empty {
    text-align: center; 
    column-span: all;
    padding-top:20px;
    background-color: #f1f1f1;
    padding-bottom:20px;
  }
