@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.comment {
  &__wrap {
    padding: 20px;
    border-bottom: 1px solid rgba(227,227,230,255);
    display: flex;
    flex-direction: row;
    height: 64px;
    position: relative;
    //align-items: center;
  }
  &__title {
    height: 100%;
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 14px;
    font-weight: 400;
    //border: 1px solid blue;
  }

  &__close-button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-image: url("/icons/close-comments.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: rgba(242,242,247,255);
    position: absolute;
    top: 14px;
    right: 12px;
    //border: 1px solid red;

    &:hover {
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
    &:focus {
      outline: none;
    }
  }

  &__compose-message {
    height: 16px;
    width: 16px;
  }

  &__top-line-wrap {
    display: flex;
    flex-direction: row;
    height: 30px;
    justify-content: center;
    align-items: center;
    //border: 1px solid red;
  }
  &__top-line-span {
    height: 2px;
    width: calc(50% - 55px);
    border: 1px solid rgba(193,193,194,255);
  }
  &__top-line-text {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 400;
    color: rgba(116,122,129,255);
    margin-left: 15px;
    margin-right: 15px;
    //border: 1px solid red;
  }
}

.confirm {
  &__title {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 24px;
    //border: 1px solid red;
  }
  &__button-wrap {
    display: flex;
    flex-direction: row;
    margin-top: 14px;
    margin-left: 150px;
    margin-bottom: 20px;
    //border: 1px solid red;
  }
  &__button-cancel {
    height: 34px;
    width: 90px;
    margin-left: 10px;
    border-radius: 7px;
    border: 1px solid rgba(153,156,161,255);
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    //color: white;
    background-color: rgba(242,242,247,255);

    &:hover {
      cursor: pointer;
      //color: white;
      //background-color: rgba(11,132,255,255);
      //border: 1px solid rgba(11,132,255,255);
    }
  }

  &__button-delete {
    height: 34px;
    min-width: 90px;
    margin-left: 10px;
    border-radius: 7px;
    border: 1px solid rgba(153,156,161,255);
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: white;
    background-color: rgba(11,132,255,255);
    border: 1px solid rgba(11,132,255,255);
    padding-left: 10px;
    padding-right: 10px;
    
    &:hover {
      cursor: pointer;
      color: white;
      background-color: rgba(11,132,255,255);
      border: 1px solid rgba(11,132,255,255);
    }

    //border: 1px solid red;
  }

  &__text {
    margin-top: 3px;
    height: 20px;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    overflow: visible;
    white-space: nowrap;
    //border: 1px solid blue;
  }
}
