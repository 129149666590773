.add-team-member-container{
    width:420px;
    flex-direction: column;;

    .title-row{
        margin-bottom:12px;
    }

    .confirm-button-wrap {
        display: flex;
        justify-content: space-between;
        margin-top:40px;

        .confirm-button-cancel {
            color: #0a84ff;
            border: solid 1px #0a84ff;
            border-radius: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 32px;
            padding-right: 32px;
        }

        .confirm-button {
            color: #ffffff;
            background-color: #0a84ff;
            border-radius: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 18px;
            padding-right: 18px;
        }

        .button-disabled {
            background-color: #f2f2f7;
            border: 1px solid #f2f2f7;
            color: #6d7278;

            &:hover {
                color: #6d7278;
                background-color: #f2f2f7;
                border: 1px solid #f2f2f7;
            } 
        }

        .line-button-disabled {
            background-color: #ffffff;
            border: 2px solid #f2f2f7;
            color: #6d7278;

            &:hover {
                color: #6d7278;
                background-color: #ffffff;
                border: 2px solid #f2f2f7;
            } 
        }
    }

    .first-name {
        display: inline-block;
    }

    .last-name {
        display: inline-block;
        margin-left:128px;
    }

    .phone-label {
        margin-top: 20px;
        font-size: 14px;
    }

    .name-wrap {

        input{
            width:176px;
            display: inline-block;
            border-radius:6px;
            border: 1px solid #d9d9d9;
            height: 36px;
            padding-left: 8px;
        }

        input:focus {
            outline:none;
        }
    }

    .country-wrap {
        display: flex;
        border-radius:6px;
        border: 1px solid #d9d9d9;
        padding-left: 4px;
        padding-top: 4px;
        padding-bottom: 4px;

        input {
            border: none;
            margin-left: 8px;
            margin-top:0px;
            width: 100%;
        }

        input:focus, textarea:focus, select:focus {
            outline-offset: 0px !important;
            outline: none !important;
        }
    }

    .check-wrap {
        margin-top: 16px;
        display: flex;

        input {
            display:inline-block;
            margin-top:0px;
        }

        label {
            display:inline-block;
            margin-left: 10px;
            font-size: 14px;
        }
    }

    .name-wrap {
        .picker-divider {
            width:20px;
            display: inline-block;
        }
    }
}

.title-row {
    justify-content: space-between;
    display:flex;

    .title {
        margin-top:4px;
    }
}

.half-dimension{
    width: 180px;
    height: 64px; 
}