.select-weekday{
    flex-direction: row;
    display: flex;
    margin-top: 4px;
}

.weekday-toggle-button {
    border-radius: 18px;
    height: 36px;
    min-width: 52px;
    font-family: 'AvertaRegular';
    font-size: 12px;
    margin-right: 8px;
    border-style: solid;
    border-width: 1px;
}

.day-selected {
    background-color: #0a84ff;
    color: #ffffff;
    border-color: #0a84ff;
}

.day-unselected {
   color: #636366; 
   background-color: #ffffff;
   border-color: #636363;
}