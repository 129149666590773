
.finish-pasting-container {
    left: 20px;
    right: 0;
    bottom: 120px;
    margin: auto;
    display: flex;
    border: 2px solid #2C2C2E;
    border-radius: 12px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    position: absolute;
    width: 320px;
    height: 88px;
    background-color: #ffffff;
    
    .copied-shift-wrap {
        margin: 5px;
        display: flex;
        flex-direction: column; 
        margin-right: 24px;
        color: #2C2C2E;
        margin-left: 10px;
        padding-right: 28px;

        label.copy-title {
            margin-top: 6px;
            font-size: 14px;
            @font-face {
                font-family: 'AvertaSemiBold';
                src: url('/fonts/averta/Averta-Semibold.ttf');
              }
              font-family: 'AvertaSemiBold';  
        }

        label.shift-info {
            font-size: 13px;
            font-family: 'AvertaRegular';
            @font-face {
                font-family: 'AvertaRegular';
                src: url('/fonts/averta/Averta-Regular.ttf');
            } 
        }

        label.position-hour-info {
            font-family: 'AvertaRegular';
            @font-face {
                font-family: 'AvertaRegular';
                src: url('/fonts/averta/Averta-Regular.ttf');
            } 
            font-size: 13px;
            font-weight: 400;
            height: 15px;
            padding-top: 0;
            text-overflow: ellipsis;
            max-width: 100px;
            white-space: nowrap;
            overflow-x:hidden;
            overflow-y:hidden;
            cursor: pointer;
            margin-top: 10px;
        }

        .position-duration-wrap {
            display: flex;

            label.position-label {
                font-family: 'AvertaRegular';
                @font-face {
                    font-family: 'AvertaRegular';
                    src: url('/fonts/averta/Averta-Regular.ttf');
                } 
                font-size: 12px;
                font-weight: 400;
                height: 15px;
                padding-top: 0;
                text-overflow: ellipsis;
                max-width: 100px;
                white-space: nowrap;
                overflow-x:hidden;
                overflow-y:hidden;
                cursor: pointer;
            }

            span.span-dot {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: #000000;
                margin-top: 6px;
                margin-left: 5px;
                margin-right: 5px;
            }

            label.duration-label {
                font-family: 'AvertaRegular';
                @font-face {
                    font-family: 'AvertaRegular';
                    src: url('/fonts/averta/Averta-Regular.ttf');
                } 
                font-size: 12px;
                font-weight: 400;
                height: 15px;
                padding-top: 0;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 140px;
                cursor: pointer;
            }
        }
    }

    .confirm-button-wrap {
        background-color: #0a84ff;
        border-radius: 6px;
        height: 36px;
        margin: auto;

        .finish-pasting-button {
            color: #ffffff; 
            padding-left: 8px;
            padding-right: 8px;
            vertical-align: middle;
            height: 36px;
        }
    }
}

