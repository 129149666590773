.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  background-color: #2c2c2c; // Background color matching the image
  color: #ffffff;
  padding: 0 15px;
  position: relative;

  .header-logo {
    width: 32px;
    height: 32px;
    margin-right: auto; // Pushes the header logo to the left-most
  }

  .center-text {
    display: flex;
    align-items: center;
    background-color: #0A84FF; // Blue background
    color: white;
    height: 36px;
    padding: 4px 12px;
    border-radius: 8px;
    position: absolute; // Positioning it centrally
    left: 50%; // Move to 50% of the container width
    transform: translateX(-50%); // Center it by adjusting for its own width

    span {
      font-size: 14px;
      letter-spacing: -0.18px;
      font-weight: 300;
      margin-left: 12px;
      margin-right: 24px;

      @font-face {
        font-family: 'AvertaBold';
        src: url('/fonts/averta/Averta-Bold.ttf');
      }

      font-family: 'AvertaBold';
    }

    .view-button {
      background-color: #ffffff; // White background for button
      color: #2C2C2E; // Blue text color
      border: none;
      height: 24px;
      border-radius: 8px;
      padding: 4px 12px;
      font-size: 12px;
      letter-spacing: -0.15px;

      @font-face {
        font-family: 'AvertaBold';
        src: url('/fonts/averta/Averta-Bold.ttf');
      }

      font-family: 'AvertaBold';
      cursor: pointer;

      &:hover {
        background-color: #e6f0ff; // Light blue on hover
      }
    }
  }

  .right-content {
    display: flex;
    align-items: center;
    justify-content: flex-end; // Align the content to the right-most side
    width: 100%;

    .left-section {
      display: flex;
      margin-right: 32px;
      align-items: center;
      justify-content: center;
      position: relative; 

      .logo {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 16px;
        object-fit: contain;
      }

      .text-section {
        display: flex;
        flex-direction: column;

        .title {
          @font-face {
            font-family: 'AvertaSemibold';
            src: url('/fonts/averta/Averta-Semibold.ttf');
          }

          font-family: 'AvertaSemibold';
          width: 16ch;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 300;
          color: white;
          letter-spacing: -0.18px;
        }

        .subtitle {
          @font-face {
            font-family: 'AvertaRegular';
            src: url('/fonts/averta/Averta-Regular.ttf');
          }

          font-family: 'AvertaRegular';
          font-size: 12px;
          color: white;
          letter-spacing: -0.17px;
        }
      }

      .chevron {
        margin-left: 16px;
        font-size: 16px;
        color: white;
        cursor: pointer;
      }
    }

    .right-section {
      display: flex;
      margin-right: 16px;
      align-items: center;

      .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }
  }
}