@keyframes flicker {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.3; }
}

.flicker {
  display:flex;
}

.flicker-once {
  animation: flicker 0.5s infinite;
}