@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.calendar-header {
  
  &__import-button {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100px;
    height: 38px;
    margin-right: 20px;
    color: white; //rgba(117,117,117,255);
    background-color: rgba(0,135,255,255);
    border-radius: 7px;
    text-align: center;
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 15px;
    font-weight: 600;

    &:hover {
      color: rgba(0,135,255,255);
      background-color: white;
      border: 1px solid rgba(0,135,255,255);
    }
    //border: 1px solid red;
  }
}

.header-row-wrap {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-row-btn {
  margin-right:24px;
  height: 40px;
  margin-top: auto;
  margin-bottom: auto;
  padding-left:22px;
  padding-right:22px;
  color: #2C2C2E; 
  @font-face {
    font-family: 'AvertaSemiBold';
    src: url('/fonts/averta/Averta-Semibold.ttf');
  }
  font-family: 'AvertaSemiBold';
  font-size: 14px;
  font-weight: 200;
  border-radius: 7px;
  border: 1px solid #2c2c2e;
}

.float-right {
  margin-left:auto;
}

.header-right-group {
  margin-top: 24px;
}

.publish-button {
  background-color: #0a84ff;
  color: #ffffff;
}

.nothing-to-publish {
  background-color: #f2f2f7;
  color: #6D7278;
}

.header-button {
  border-radius: 8px;
  border: 1px solid #6D7278;
  padding-top:8px;
  padding-bottom:8px;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 14px;
  font-family: 'AvertaSemibold';
  margin-right: 24px;
}

a.header-button {
  text-decoration: none;
  color: #2C2C2E;
}

ul.export-menu {
  margin-top: 2px;
  border: 1px solid #6D7278;
  border-radius: 8px;
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 8px;
  padding-top: 4px;
  font-size: 14px;
  position: absolute;
  z-index: 2;
  background-color: #ffffff;

  li.item {
    margin-top: 8px;
    text-decoration: none;
    list-style-type: none;
    a {
      text-decoration: none;
      color: #2c2c2e;
    }
  }
}

.shown {
  display: block;
}

.not-shown {
  display:none;
}

.dropdown-menu {
  cursor:pointer; 
  padding: 0px;

  .dropdown-item {
    padding-top: 8px;
    padding-bottom: 8px;
    height: 42px;
  }

  .dropdown-item a{
    text-decoration: none;
    color: #2c2c2e;
  }

  .dropdown-item {
    &:hover {
      background-color: #DAEDFF;
    }
  }
}

li.danger-action {
  color: #ff4650; 
}

li.danger-action {
  &:hover {
    color: #ff4650; 
  }
}