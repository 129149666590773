@import 'stylesheets/_vars.scss';

.shyft-table--wrapper {
  position: relative;
  width: 100%;
  max-width: 1462px;
  min-height: 200px;
  margin-bottom: 35px;
  // background-color: white;
  margin: auto;
}

.shyft-table {
  z-index: 1;
  background-color: $neutralGreyColor;
  // table-layout: fixed;
  // width: 1462px;
  width: 100%;
  margin-bottom: 50px;

  &.loading {
    z-index: -1;
  }

  & tbody {
    & tr {
      min-height: 75px;

      &:nth-child(2n) {
        background-color: $semiDarkGreyColor;
      }

      &:nth-child(odd) {
        background-color: white;
      }
    }

    & td {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 1px;
    }
  }
}

.shyft-table--loading-layer {
  position: absolute;
  top: 80px;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  display: none;

  &.show {
    display: block;
    z-index: 1;
    opacity: 0.6;

    & img {
      position: relative;
      height: 50px;
      width: 50px;
      top: 50px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.shyft-table--headers {
  color: $headerColor;
  background-color: lighten($lightFontColor, 25%);
  text-align: left;

  & th {
    padding-top: 15px;
    padding-bottom: 15px;
    height: 50px;
  }

  & th,
  & td {
    padding-left: 15px;
    padding-right: 15px;
    // min-width: 216px;
  }
}
