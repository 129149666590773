.count-mouse-tip {
  &__top-wrap {
    margin-top: 1px;
    margin-left: 15px;
    margin-right: 15px;
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 600;
    //border: 1px solid red;
  }

  &__wrap {
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 600;
    //border: 1px solid red;
  }

  &__others {
    //margin-top: 1px;
    margin-left: 5px;
    //margin-right: 15px;
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 600;
    color: rgba(11,132,255,255);
    border-bottom: 1px solid rgba(11,132,255,255);

    &:hover {
      cursor: pointer;
    }
  }
}