@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.linkuser {
    &_container {
        max-height: 612px;
        height:fit-content;
        width: fit-content;
        position: absolute;
        background-color: white; //rgba(242,242,247,255);
        box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
        z-index: 4; 
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
        border-radius: 10px;
        top:0;
        bottom:0;
        right:0;
        left:0;
        margin:auto;
      
        @media (max-width: 800px) {
          left: 50px;
          min-width: 600px;
        }

        input:focus {
            outline: none;
        }
    }

    &_table-wrap{
        overflow-y: auto;
        max-height: 370px;
        margin-top:20px;
    }


    &_unscheduled-name {
        display:flex;
    }


    &_unregistered-selected-user-text {
        margin-left: 5px;
        font-family: 'AvertaRegular';
        font-size: 12px;
        margin-bottom: 0px;
    }

    &_unregistered-user-wrap {
        display:flex;
        flex-direction: column;
        max-width:600px;
        margin-left: 8px;
    }

    &_header {
        display:flex;
        flex-direction: row;
    }

    &_header img {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        margin-top: 2px;
    }

    &_unscheduled-user-wrap {
        width:560px;
        margin-left: 8px;
    }

    &_title {
        font-family: 'AvertaRegular';
        font-size: 20px;
        font-weight: 200; 
    }

    &_unregistered-name {
        font-family: 'AvertaSemiBold';
        font-size: 14px;
        margin-bottom:0px;
    }

    &_unregistered-job {
        font-family: 'AvertaRegular';
        font-size: 12px;
    }

    &_confirm-button-cancel{
        height: 34px;
        width: 90px;
        margin-left: 10px;
        border-radius: 7px;
        border: 1px solid rgba(153,156,161,255);
        @font-face {
        font-family: 'AvertaBold';
        src: url('/fonts/averta/Averta-Bold.ttf');
        }
        font-family: 'AvertaBold';
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        background-color: rgba(242,242,247,255);

        &:hover {
            cursor: pointer;
        }
    }

    &_confirm-button {
        height: 34px;
        width: 90px;
        margin-left: 10px;
        border-radius: 7px;
        border: 1px solid rgba(153,156,161,255);
        @font-face {
        font-family: 'AvertaBold';
        src: url('/fonts/averta/Averta-Bold.ttf');
        }
        font-family: 'AvertaBold';
        font-size: 12px;
        font-weight: 400;
        text-align: center;

        color: white;

        &:hover {
            cursor: pointer;
            color: white;
        }
    }

    &_button-enabled {
        background-color: rgba(11,132,255,255);
        border: 1px solid rgba(11,132,255,255);
        &:hover {
            background-color: rgba(11,132,255,255);
            border: 1px solid rgba(11,132,255,255);
        }
    }

    &_button-disabled {
        background-color: #f2f2f7;
        border: 1px solid #f2f2f7;
        color: #6d7278;

        &:hover {
            color: #6d7278;
            background-color: #f2f2f7;
            border: 1px solid #f2f2f7;
        }
    }

    &_confirm-button-wrap {
        margin-top:15px;
        margin-right:0px;
        text-align:right;
    }

    &_search-box {
        margin-top:10px;
        width:100%;
        border-radius: 4px;
        border: 1px solid #aaaaaa;
        padding-left:10px;
        padding-top:2px;
        padding-bottom:2px;
    }

}
